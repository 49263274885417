import React, { useEffect } from "react"
import { useStore } from "@store/index"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"

import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import FormTextField from "@framework/prototypes/FormTextField"
import clientService from "@services/client.service"

import { CompanyFormValues, initialValues, validationSchema } from "./utils"
import PhoneInputField from "./components/PhoneInputField"
import AccessLevelSelector from "./components/AccessLevelSelector"
import FormSelect from "./components/FormSelect"

// import YearlyBudgetField from "./components/YearlyBudgetField"

const formFields = [
  { name: "name", label: "Client name", component: FormTextField },
  { name: "clientEmail", label: "Client email", component: FormTextField },
  { name: "contactName", label: "Contact name", component: FormTextField },
  { name: "contactPhone", label: "Contact phone", component: PhoneInputField },
  // { name: "website", label: "Website", component: FormTextField },
  // { name: "state", label: "State", component: FormTextField },
  // { name: "city", label: "City", component: FormTextField },
  // { name: "postal", label: "Postal", component: FormTextField },
  // { name: "street", label: "Street", component: FormTextField },
  // {
  //   name: "yearlyAdBudget",
  //   label: "Yearly Ad Budget",
  //   component: YearlyBudgetField,
  // },
  { name: "accountName", label: "Account name", component: FormTextField },
]

export const CompanySetup: React.FC = observer(() => {
  const alert = useAlert()
  const history = useHistory()
  const {
    clientProfileStore: { markets, industries, fetchIndustries, fetchMarkets },
    authStore: { setOnboardedUser, user },
  } = useStore()

  useEffect(() => {
    fetchIndustries()
    fetchMarkets()
  }, [])

  const formik = useFormik<CompanyFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      const formattedData = {
        ...data,
        accountIndsutryId: +data.accountIndsutryId,
        accountMarketId: +data.accountMarketId,
      }
      let res
      if (user?.isOnboarded) {
        res = await clientService.createAgencyClient(formattedData)
      } else {
        res = await clientService.onboardUser(formattedData)
      }
      if (res.data.data.id) {
        setOnboardedUser()
        history.push(`/client/${res.data.data.id}/profile`)
      } else {
        alert.error("Error of creating new item")
      }
    },
  })

  const valid = Object.keys(formik.touched).length && formik.isValid

  return (
    <FormikProvider value={formik}>
      <Stack gutter="8" align="stretch">
        <AccessLevelSelector
          value={formik.values.accessLevel}
          onChange={formik.handleChange}
        />
        {formFields.map(({ name, label, component: Component }) => (
          <Component key={name} label={label} name={name} />
        ))}
        <FormSelect label="Market:" name="accountMarketId" options={markets} />
        <FormSelect
          label="Industry"
          name="accountIndsutryId"
          options={industries}
        />
        <Stack direction="row" justify="flex-end">
          <Button disabled={!valid} onClick={formik.submitForm}>
            Save and start connecting the Platforms
          </Button>
        </Stack>
      </Stack>
    </FormikProvider>
  )
})

export default CompanySetup
