import React, { useMemo, useState } from "react"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { mbNoData } from "@services/utils"
import TimePeriodPicker from "@components/ui/DatePicker/TimePeriodPicker"
import { printDateRange } from "@utils/date"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"

import styles from "./DashboardHeader.module.scss"

const DashboardSelectListMock = [
  { title: "Google Ads" },
  { title: "Facebook Ads" },
]

const DashboardHeader = () => {
  const {
    reports: { mainFilter },
  } = useStore()
  const currPeriod = mainFilter.currPeriod.selectedOptions[0]
  const prevPeriod = mainFilter.prevPeriod.selectedOptions[0]

  return (
    <Stack direction="row" justify="space-between" className={styles.root}>
      <span>
        <div className={styles.input}>
          <Typography
            className={styles.periodLabel}
            type="h5"
            color="black100Color"
          >
            {mbNoData(currPeriod?.label)}
          </Typography>
          <TimePeriodPicker
            range={currPeriod}
            prev={prevPeriod}
            rangeOptions={mainFilter.currPeriod.options}
            prevOptions={mainFilter.prevPeriod.options}
            onRangeChange={(o) => mainFilter.currPeriod.updateOption(o, true)}
            onPrevChange={(v) => mainFilter.prevPeriod.select(v.value, true)}
          />
        </div>
        <Typography className={styles.helperText} type="caption2" color="gray">
          {mbNoData(prevPeriod?.label)}
          {": "}
          {mbNoData(prevPeriod?.range, printDateRange)}
        </Typography>
      </span>
      <Stack direction="row" align="center">
        <Typography type="h5" color="black100Color">
          Channels
        </Typography>
        <SimpleSelect
          options={DashboardSelectListMock}
          onSelect={(option) => {
            console.log(option)
          }}
          value={DashboardSelectListMock[0].title}
        />
      </Stack>
    </Stack>
  )
}

export default DashboardHeader
