import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import {
  MetricDescription,
  MetricNameType,
  MetricNameTypeV1,
} from "@framework/types/metrics"
import { metricsToDisplay } from "@store/reports/constants"
import {
  metricsDescriptors,
  metricsDescriptorsMap,
} from "@framework/constants/metrics"
import Loader from "@components/ui/Loader/Loader"
import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import { metricOptions } from "@framework/types/creativeAnalytics"
import KPIsCards from "../KPIsCards/KPIsCards"
import MetricsTable from "../MetricsTable/MetricsTable"
import mapper, { CheckboxItem, defaultElementOrder } from "./utils"
import MetricsSideBar from "../MetricsSideBar/MetricsSideBar"

import styles from "./OverallKPIs.module.scss"

const MOCK_DATA = [
  {
    Id: "328794632",
    Name: "Google Ads",
    AOV: { Value: 0 },
    AverageCpc: { Value: 1.6712, Change: 0.7021393525458816 },
    Clicks: { Value: 4, Change: -0.6666666666666666 },
    ConversionRate: { Value: 0 },
    Conversions: { Value: 0 },
    Cost: { Value: 6.69, Change: -0.4326202639575899 },
    CostConv: { Value: 0 },
    Cpm: { Value: 94.225352, Change: 1.2215714072508859 },
    Ctr: {
      Value: 0.05633802816901408,
      Change: 0.30516431924882625,
    },
    Impressions: { Value: 71, Change: -0.7446043165467626 },
    Revenue: { Value: 0 },
    Roas: { Value: 0 },
    Holdplay: { Value: 0, Change: -1 },
  },
  {
    Id: "3287632",
    Name: "Facebook",
    AOV: { Value: 0 },
    AverageCpc: { Value: 1.6712, Change: 0.7021393525458816 },
    Clicks: { Value: 4, Change: -0.6666666666666666 },
    ConversionRate: { Value: 0 },
    Conversions: { Value: 0 },
    Cost: { Value: 6.69, Change: -0.4326202639575899 },
    CostConv: { Value: 0 },
    Cpm: { Value: 94.225352, Change: 1.2215714072508859 },
    Ctr: {
      Value: 0.05633802816901408,
      Change: 0.30516431924882625,
    },
    Impressions: { Value: 71, Change: -0.7446043165467626 },
    Revenue: { Value: 0 },
    Roas: { Value: 0 },
    Holdplay: { Value: 0, Change: -1 },
  },
]

const initMetrics = [
  ...defaultElementOrder.map((el) => ({
    label: metricOptions?.[el as MetricNameTypeV1],
    name: el,
    checked: metricsToDisplay.includes(el),
  })),
]

type OverallKPIsProps = {
  className?: string
}

export interface Mapper {
  title: string
  subTitle: string
  blockMapper: MetricDescription[]
}

const OverallKPIs: React.FC<OverallKPIsProps> = observer(({ className }) => {
  const { accountDashboardStore, accountStore } = useStore()
  const { accountId, account } = accountStore
  const {
    loadPerformance,
    loadingPerformance: loading,
    currPeriod,
  } = accountDashboardStore

  const [metrics, setMetrics] = useState<CheckboxItem[]>([])
  const [appliedMetrics, setApliedMetrics] =
    useState<CheckboxItem[]>(initMetrics)
  const [isOpenMetricsSideBar, setIsOpenMetricsSideBar] =
    useState<boolean>(false)

  const handleSaveOptionsValus = () => {
    setApliedMetrics(metrics)
    setIsOpenMetricsSideBar(false)
  }

  const handleMetricsOptions = (value: MetricNameType) => {
    setMetrics((prev) => {
      const findedIndex = prev.findIndex((el) => el.name === value)
      if (findedIndex !== -1) {
        return prev.map((item, index) =>
          index === findedIndex ? { ...item, checked: !item.checked } : item
        )
      }
      return prev
    })
  }
  const hanldeOpenMetricsMenu = () => {
    setIsOpenMetricsSideBar(true)
    setMetrics(appliedMetrics)
  }

  const handleCancelMetricsMenu = () => {
    setIsOpenMetricsSideBar(false)
    setMetrics(appliedMetrics)
  }

  useEffect(() => {
    if (accountId == null && !account?.isConnected) return
    loadPerformance(accountId!, currPeriod.from, currPeriod.to)
  }, [accountId, currPeriod, loadPerformance, account?.isConnected])

  return (
    <>
      <div className={clsx(styles.root, className)}>
        <Stack
          direction="row"
          justify="space-between"
          className={styles.header}
        >
          <Typography type="h1" color="black100Color" weight="bold">
            Overall KPIs
          </Typography>
          <div className={styles.filterButton}>
            <Button
              color="secondary"
              before={<Icon name="eye" />}
              onClick={hanldeOpenMetricsMenu}
            >
              Metrics
            </Button>
            {/* {showMetrics && (
              <div className={styles.metricsMenu}>
                <SimpleFiltersMultiselect
                  className={styles.select}
                  inputClassName={styles.selectInput}
                  withSearch
                  onSelect={(option) => {
                    handleMetricsOptions(option as MetricNameType)
                  }}
                  value={Array.from(new Set([...metricOptionsValues]))}
                  placeholder="Search metrics"
                  onClickOutside={() => setShowMetrics(false)}
                  {...metricsOptions}
                />
              </div>
            )} */}
          </div>
        </Stack>

        {!loading ? (
          <>
            <KPIsCards
              metricOptionsValues={appliedMetrics
                .filter((el) => el.checked)
                .map((el) => el.name)}
            />
            <MetricsTable
              mapper={Object.values(
                mapper(
                  appliedMetrics
                    .filter((el) => el.checked)
                    .map((el) => metricsDescriptorsMap[el.name])
                )
              )}
              data={MOCK_DATA}
            />
          </>
        ) : (
          <Loader />
        )}
      </div>
      <MetricsSideBar
        setMetrics={setMetrics}
        metrics={metrics}
        onSelect={handleMetricsOptions}
        isOpen={isOpenMetricsSideBar}
        onSave={handleSaveOptionsValus}
        onCancel={handleCancelMetricsMenu}
        onReset={() => {
          setMetrics(initMetrics)
        }}
      />
    </>
  )
})

export default OverallKPIs
