import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import SelectAccountChannelModal from "@components/modals/SelectAccountChannelModal/SelectAccountChannelModal"
import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import Typography from "@components/ui/Typography/Typography"
import useQueryParams from "@framework/hooks/useQueryParams"
import { mbNoData } from "@services/utils"
import { useStore } from "@store/index"
import { useAlert } from "react-alert"
import {
  CustomerType,
  LinkedAccount,
  LinkedAccountKeys,
} from "@store/account-linking/account-linking"
import ConfirmModal from "@components/modals/ConfirmModal/ConfirmModal"
import useAccountLinkRedirect from "@framework/prototypes/LinkAccount/useAccountLinkRedirect"
import SelectCustomerModal from "@components/modals/SelectCustomerModal/SelectCustomerModal"
import { LinkAccountCases } from "@framework/prototypes/LinkAccount/LinkAccountRedirect"
import useToggle from "@framework/hooks/useToggle"
import accountService from "@services/account.service"
import LinkFacebookAccountModal from "@components/modals/LinkFacebookAccountModal/LinkFacebookAccountModal"
import useMetaAccountLinkRedirect from "@framework/prototypes/LinkAccount/useMetaAccountLinkRedirect"

import styles from "./LinkedAccounts.module.scss"

const emptyFieldForLinkedAccount = {
  adwordsAccount: "",
  adwordsAccountName: "",
  adwordsManagerAccount: "",
}

type LinkedAccountsProps = {
  linkCase?: LinkAccountCases
  className?: string
}

const mapper = [
  { name: "channel", label: "Channel" },
  {
    name: "accountName",
    label: "Account name",
    renderCallback: (value: string) => mbNoData(value),
  },
  {
    name: "adAccountId",
    label: "Customer id",
  },
  { name: "status", label: "Status" },
  { name: "adoroAccountName", label: "Adoro Account Name" },
]

const formMapper = [
  { name: "channel", label: "Channel" },
  { name: "accountName", label: "Account name" },
  {
    name: "adAccountId",
    label: "Customer id",
  },
  { name: "status", label: "Status" },
  { name: "adoroAccountName", label: "" },
]

const LinkedAccounts: React.FC<LinkedAccountsProps> = observer(
  ({ className, linkCase = "account" }) => {
    const {
      manageProfileStore: { isEdit, setIsEdit, setSubmitCallback },
      accountLinkingStore: {
        linkedGoogleAccount,
        linkedFacebookAccount,
        linkPending,
        linkAccount,
        loadCustomerList,
      },
      accountStore: {
        accountId,
        loading: accountLoading,
        loadAccount,
        updateAccountInfo,
      },
    } = useStore()

    const [code, setCode] = useState<string>()
    const [accessToken, setAccessToken] = useState<string>()
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
    const [connectedStatuses, setConnectedStatuses] = useState<{
      linkedGoogleAccount: boolean
      linkedFacebookAccount: boolean
    }>({
      linkedGoogleAccount: !!linkedGoogleAccount?.isConnected,
      linkedFacebookAccount: !!linkedFacebookAccount?.status,
    })

    const customersModal = useToggle(false)
    const channelModal = useToggle(false)
    const connectMetaAccountModal = useToggle(false)

    const history = useHistory()
    const params = useQueryParams()
    const alert = useAlert()

    const { redirect: linkRedirect } = useAccountLinkRedirect()
    const { redirect: linkMetaRedirect } = useMetaAccountLinkRedirect()

    const handleNewAccountClick = (item: string) => {
      if (!accountId) return

      if (item === "googleAds") {
        linkRedirect(linkCase, { id: accountId })
      }

      if (item === "facebookAds") {
        linkMetaRedirect(accountId)
      }

      channelModal.setOpened(false)
    }

    const handleSelectChannel = () => channelModal.setOpened(true)

    const handleLinkModalClose = () => {
      customersModal.setOpened(false)
      loadAccount()
      setIsEdit(false)
    }

    const handleLinkAccount = async (selected: CustomerType) => {
      if (!accountId) return
      const error = await linkAccount(accountId, selected)
      if (error == null) customersModal.setOpened(false)
    }

    useEffect(() => {
      const { code, access_token } = params
      if (code) {
        setCode(code)
        history.replace(history.location.pathname)
      }
      if (access_token) {
        setAccessToken(access_token)
        history.replace(history.location.pathname)
      }
    }, [params])

    useEffect(() => {
      if (accountId && code) {
        setIsEdit(true)
        loadCustomerList(code, accountId, "account")
        customersModal.setOpened(true)
      }
    }, [code])

    useEffect(() => {
      if (accountId && accessToken) {
        setIsEdit(true)
        connectMetaAccountModal.setOpened(true)
      }
    }, [accessToken, accountId])

    useEffect(() => {
      if (accountId) {
        setSubmitCallback(() => {
          updateAccountInfo(accountId, {
            isConnected: connectedStatuses.linkedGoogleAccount,
          })
          setIsEdit(false)
        })
      }
    }, [connectedStatuses])

    useEffect(() => {
      if (!isEdit)
        setConnectedStatuses({
          linkedGoogleAccount: !!linkedGoogleAccount?.isConnected,
          linkedFacebookAccount: !!linkedFacebookAccount?.status,
        })
    }, [isEdit, linkedGoogleAccount, linkedFacebookAccount])

    useEffect(() => () => setIsEdit(false), [])

    const handleDelete = async () => {
      if (accountId) {
        try {
          await accountService.updateAccountInfo(
            accountId,
            emptyFieldForLinkedAccount
          )
          alert.success("Account is deleted")
        } catch (err) {
          console.log(err)
        } finally {
          setIsEdit(false)
          loadAccount()
          setIsConfirmModalOpen(false)
        }
      }
    }

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h2" className={styles.title}>
          Linked accounts
        </Typography>
        <Loader isLoading={accountLoading} />
        <div className={styles.container}>
          {linkedGoogleAccount || linkedFacebookAccount ? (
            isEdit ? (
              <>
                {linkedGoogleAccount != null && (
                  <RowForm
                    isConnected={connectedStatuses.linkedGoogleAccount}
                    data={linkedGoogleAccount}
                    key={linkedGoogleAccount.adAccountId}
                    changeStatus={() => {
                      setConnectedStatuses((prev) => ({
                        ...prev,
                        linkedGoogleAccount: !prev.linkedGoogleAccount,
                      }))
                    }}
                    onDelete={() => setIsConfirmModalOpen(true)}
                  />
                )}

                {linkedFacebookAccount != null && (
                  <RowForm
                    isConnected={!!linkedFacebookAccount.status}
                    data={linkedFacebookAccount}
                    key={linkedFacebookAccount.adAccountId}
                    changeStatus={() => {}} // NOTE: it`s only mock for now
                  />
                )}
              </>
            ) : (
              <>
                <div className={styles.row}>
                  {mapper.map(({ name, label }) => (
                    <Typography
                      type="subhead1"
                      color="gray"
                      upperCase
                      key={name}
                    >
                      {label}
                    </Typography>
                  ))}
                </div>

                {linkedGoogleAccount != null && (
                  <Row
                    data={linkedGoogleAccount}
                    key={linkedGoogleAccount.adAccountId}
                  />
                )}

                {linkedFacebookAccount != null && (
                  <Row
                    data={linkedFacebookAccount}
                    key={linkedFacebookAccount.adAccountId}
                  />
                )}
              </>
            )
          ) : (
            <NoData>None linked accounts added</NoData>
          )}
          {isEdit && (
            <Button
              onClick={handleSelectChannel}
              startIcon={{ name: "plus", circle: true }}
              className={styles.button}
            >
              Link Account
            </Button>
          )}
        </div>
        <SelectCustomerModal
          isOpen={customersModal.isOpened}
          isLoading={linkPending}
          onSubmit={handleLinkAccount}
          onClose={handleLinkModalClose}
        />

        {channelModal.isOpened && (
          <SelectAccountChannelModal
            isOpen
            onClose={() => channelModal.setOpened(false)}
            onSubmit={handleNewAccountClick}
          />
        )}

        {connectMetaAccountModal.isOpened && accountId && accessToken && (
          <LinkFacebookAccountModal
            isOpen
            onClose={() => connectMetaAccountModal.setOpened(false)}
            accessToken={accessToken}
            accountId={accountId}
          />
        )}

        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          message="This action will Delete your current Ads Account from Adoro. You will not be able to see analytics or create Ads and Campaigns from Adoro app. Are you sure?"
          onApply={handleDelete}
          applyText="Yes"
          cancelText="No"
          title="Delete linked account?"
        />
      </div>
    )
  }
)

type RowProps = {
  data: LinkedAccount
}

type RowFormProps = {
  isConnected: boolean
  changeStatus: () => void
  onDelete?: () => void
} & RowProps

const Row: React.FC<RowProps> = ({ data }) => (
  <Box className={styles.row} padding="8px 6px 6px 6px">
    {mapper.map(({ name, renderCallback }) => {
      const item = data[name as LinkedAccountKeys]
      if (item === undefined) return null
      if (name === "status") {
        return (
          <Typography color="gray" className={styles.cell} key={name}>
            {data.isConnected ? "Connected" : "Disconnected"}
          </Typography>
        )
      }
      if (typeof item !== "string" && typeof item !== "boolean") {
        return (
          <Typography color="gray" className={styles.cell} key={name}>
            <img src={item.iconSrc} alt={item.label} className={styles.icon} />
            {item.label}
          </Typography>
        )
      }
      return (
        <Typography color="gray" className={styles.cell} key={name}>
          {renderCallback?.(item as string) ?? item}
        </Typography>
      )
    })}
  </Box>
)

const RowForm: React.FC<RowFormProps> = ({
  data,
  isConnected,
  changeStatus,
  onDelete,
}) => (
  <Box className={styles.box}>
    <div className={styles.rowContainer}>
      {formMapper.map(({ label, name }) => (
        <Typography type="subhead1" color="gray" upperCase key={name}>
          {label}
        </Typography>
      ))}
    </div>
    <div className={styles.rowContainer}>
      <div style={{ zIndex: 1 }}>
        <Button variant="outlined">
          <span>{data.channel.label} Link</span>
          <div className={styles.iconWrapper}>
            <Icon name="done" />
          </div>
        </Button>
      </div>
      <Typography type="h4">{data.accountName || "-"}</Typography>
      <Typography type="h4">{data.adAccountId}</Typography>
      <div className={styles.status}>
        <Typography type="h4" color="primary">
          {isConnected ? "Connected" : "Disconnected"}
        </Typography>
        <ToggleButton onChange={changeStatus} checked={isConnected} />
      </div>
      <div style={{ zIndex: 1, justifySelf: "flex-end" }}>
        <Button
          variant="outlined"
          startIcon={{ name: "trash" }}
          onClick={onDelete}
        >
          <span>Delete</span>
        </Button>
      </div>
    </div>
  </Box>
)

export default LinkedAccounts
