import React, { FC } from "react"
import { useFormik } from "formik"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import Divider from "@components/ui/Divider/Divider"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import Button from "@components/ui/Button/Button"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import Templates from "@components/ui/Templates"
import { ShopifySettingOptions } from "@framework/types/account"

import styles from "./SideBarSettings.module.scss"

interface BaseSideBarSettingProps extends ShopifySettingOptions {
  show: boolean
  onClose: () => void
  onApply: (settingOptions: ShopifySettingOptions) => void
  onCancel: (settingOptions: ShopifySettingOptions) => void
}

type OutOfStockOption = "email" | "metaAds"
type InStockOption = "email" | "metaAds"

interface FormValues {
  outOfStockOption: OutOfStockOption
  inStockOption: InStockOption
}

const BaseSideBarSetting: FC<BaseSideBarSettingProps> = ({
  onClose,
  show,
  onApply,
  onCancel,
  emailBackInStock,
  emailOutOfStock,
  metaAdsPauseOutOfStock,
  metaAdsStartInStock,
}) => {
  const formik = useFormik<FormValues>({
    initialValues: {
      outOfStockOption: metaAdsPauseOutOfStock ? "metaAds" : "email",
      inStockOption: metaAdsStartInStock ? "metaAds" : "email",
    },
    onSubmit: (data) => {
      const settingOptions: ShopifySettingOptions = {
        emailOutOfStock: data.outOfStockOption === "email",
        metaAdsPauseOutOfStock: data.outOfStockOption === "metaAds",
        emailBackInStock: data.inStockOption === "email",
        metaAdsStartInStock: data.inStockOption === "metaAds",
      }
      onApply(settingOptions)
      onClose()
    },
    enableReinitialize: true,
  })

  const handleClose = () => {
    formik.setValues({
      outOfStockOption: metaAdsPauseOutOfStock ? "metaAds" : "email",
      inStockOption: metaAdsStartInStock ? "metaAds" : "email",
    })
    onClose()
  }

  return (
    <SidebarContainer
      className={styles.root}
      isOpen={show}
      onClose={() => {
        const currentValues: ShopifySettingOptions = {
          emailOutOfStock: formik.values.outOfStockOption === "email",
          metaAdsPauseOutOfStock: formik.values.outOfStockOption === "metaAds",
          emailBackInStock: formik.values.inStockOption === "email",
          metaAdsStartInStock: formik.values.inStockOption === "metaAds",
        }
        onCancel(currentValues)
      }}
    >
      <Templates.Header
        offset="none"
        className={styles.header}
        left={
          <Typography type="h2" weight="bold">
            Settings: Connect to Meta Ads
          </Typography>
        }
      />
      <form onSubmit={formik.handleSubmit} className={styles.checkboxContainer}>
        <Typography type="h3" weight="semibold">
          Products go Out of Stock
        </Typography>
        <Stack className={styles.checkboxSection}>
          <InlineLabel
            text="send an email notification only"
            color="black60Color"
          >
            <Radio
              id="outOfStock_email"
              name="outOfStockOption"
              value="email"
              checked={formik.values.outOfStockOption === "email"}
              onChange={formik.handleChange}
            />
          </InlineLabel>

          {/* <InlineLabel
            text="pause connected Meta Ads automatically (+ send email notification)"
            color="black60Color"
          >
            <Radio
              id="outOfStock_metaAds"
              name="outOfStockOption"
              value="metaAds"
              checked={formik.values.outOfStockOption === "metaAds"}
              onChange={formik.handleChange}
            />
          </InlineLabel> */}
        </Stack>
        <div className={styles.divider}>
          <Divider />
        </div>
        <Typography type="h3" weight="semibold">
          Products become back In Stock
        </Typography>
        <Stack className={styles.checkboxSection}>
          <InlineLabel
            text="send an email notification only"
            color="black60Color"
          >
            <Radio
              id="inStock_email"
              name="inStockOption"
              value="email"
              checked={formik.values.inStockOption === "email"}
              onChange={formik.handleChange}
            />
          </InlineLabel>
          {/* 
          <InlineLabel
            text="restart connected Meta Ads automatically (+ send email notification)"
            color="black60Color"
          >
            <Radio
              id="inStock_metaAds"
              name="inStockOption"
              value="metaAds"
              checked={formik.values.inStockOption === "metaAds"}
              onChange={formik.handleChange}
            />
          </InlineLabel> */}
        </Stack>
        <div className={styles.divider}>
          <Divider />
        </div>
      </form>
      <Templates.Header
        className={styles.footer}
        offset="none"
        left={
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        }
        right={
          <Button
            color="primary"
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            Apply
          </Button>
        }
      />
    </SidebarContainer>
  )
}

export default BaseSideBarSetting
