import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import TextField from "@components/ui/TextField/TextField"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/Button/IconButton"
import { useStore } from "@store/index"
import ConfirmDiscardAdCopiesModal from "@components/modals/ConfirmModal/ConfirmDiscardAdCopiesModal"
import useToggle from "@framework/hooks/useToggle"
import ConfirmPublishAdCopiesModal from "@components/modals/ConfirmModal/ConfirmPublishAdCopiesModal"
import { useSearchContext } from "@framework/prototypes/SearchContext/index"

import styles from "./AdCopy.module.scss"

export const Header: React.FC = observer(() => {
  const {
    adCopyStore: { isEditingAvailable },
    accountStore: { account },
  } = useStore()
  const searchContext = useSearchContext()

  useEffect(() => {
    console.log(account?.isConnected)
  }, [account?.isConnected])

  const discardToggle = useToggle()
  const publishToggle = useToggle()

  return (
    <>
      <Templates.Header
        offset="none"
        left={
          <TextField
            className={styles.searchInput}
            value={searchContext.searchQuery}
            onChange={(e) => searchContext.setSearchQuery(e.target.value)}
            placeholder="Search campaigns or ad groups"
            before={<Icon name="search" />}
            disabled={!account?.isConnected}
            after={
              searchContext.searchQuery ? (
                <IconButton onClick={() => searchContext.setSearchQuery("")}>
                  <Icon name="cross" />
                </IconButton>
              ) : null
            }
          />
        }
        right={
          <Stack direction="row" gutter="16" justify="flex-end">
            <Button
              color="secondary"
              onClick={() => {
                console.log("vfnhkf")
              }}
              disabled={!isEditingAvailable || !account?.isConnected}
            >
              Discard changes
            </Button>
            <Button
              color="primary"
              onClick={publishToggle.handleToggle}
              disabled={!isEditingAvailable || !account?.isConnected}
            >
              Publish to Google
            </Button>
          </Stack>
        }
      />

      <ConfirmDiscardAdCopiesModal
        isOpen={discardToggle.isOpened}
        onClose={discardToggle.handleToggle}
      />

      <ConfirmPublishAdCopiesModal
        isOpen={publishToggle.isOpened}
        onClose={publishToggle.handleToggle}
      />
    </>
  )
})

export default Header
