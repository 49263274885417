import React, { FC } from "react"
import { Chart, ArcElement, Tooltip, Legend, ChartOptions } from "chart.js"
import { Doughnut } from "react-chartjs-2"

Chart.register(ArcElement, Tooltip, Legend)

interface DoughutChartProps {
  data: number[]
  colors: string[]
  labels: string[]
  className?: string
}

const DoughutChart: FC<DoughutChartProps> = ({
  data,
  labels,
  className,
  colors,
}) => {
  const chartData = {
    labels: [...labels],
    datasets: [
      {
        data: [...data],
        backgroundColor: [...colors],
        borderWidth: 0,
      },
    ],
  }
  const options: ChartOptions<any> = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: "65%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `${context.label}: ${context.raw}%`,
        },
      },
      datalabels: {
        display: false,
      },
    },
  }
  return (
    <Doughnut redraw data={chartData} options={options} className={className} />
  )
}

export default DoughutChart
