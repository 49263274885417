import clsx from "clsx"
import React, { MouseEventHandler } from "react"
import { ScrollSyncPane } from "react-scroll-sync"

import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import ScrollShadow from "@components/ui/ScrollShadow/ScrollShadow"
import { ColumnMapper } from "./types"

import styles from "./RowGroup.module.scss"
import { RenderCellOptions } from "./utils"

export interface RowGroupProps<T extends object> {
  name: string
  className?: string
  mapper?: ColumnMapper<T>[]
  withShadow?: boolean
  options?: RenderCellOptions
  onClick?: MouseEventHandler
  renderCell: (
    mapper: ColumnMapper<T>,
    options?: RenderCellOptions
  ) => React.ReactNode
}

export const RowGroup = <T extends object>({
  name,
  options,
  mapper,
  className,
  withShadow = false,
  onClick,
  renderCell,
}: RowGroupProps<T>) =>
  mapper?.length ? (
    <ScrollShadow showShadow={withShadow} className={className}>
      {(ref) => (
        <ScrollSyncPane group={name}>
          <ScrollContainer
            className={clsx(styles.group)}
            type="none"
            onClick={onClick}
            ref={ref}
          >
            {mapper.map((item) => (
              <div
                className={styles.col}
                style={{
                  minWidth: item.width || "100px",
                  width: item.width || "100px",
                }}
                key={item.name.toString()}
              >
                {renderCell(item, options)}
              </div>
            ))}
          </ScrollContainer>
        </ScrollSyncPane>
      )}
    </ScrollShadow>
  ) : null

export default RowGroup
