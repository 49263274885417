import React, { FC } from "react"
import { useFormikContext } from "formik"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Label from "@components/ui/Label/Label"
import useOptionList from "@framework/prototypes/useOptionList"
import { CompanyFormValues } from "../utils"

interface FormSelectProps {
  options: {
    label: string
    value: string
  }[]
  label: string
  name: keyof CompanyFormValues
}

const FormSelect: FC<FormSelectProps> = ({ options, name, label }) => {
  const formik = useFormikContext<CompanyFormValues>()

  const cannelOptions = useOptionList(options)

  return (
    <Label label={`${label}:`}>
      {" "}
      <SimpleSelect
        value={formik.values[name]?.toString() || ""}
        onSelect={(value) => formik.setFieldValue(name, value)}
        {...cannelOptions}
      />
    </Label>
  )
}

export default FormSelect
