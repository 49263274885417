import React, { FC, useEffect } from "react"
import * as yup from "yup"
import { useAlert } from "react-alert"
import { FormikProvider, useFormik } from "formik"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import SSOButton from "@components/ui/Button/SSOButton/SSOButton"
import Divider from "@components/ui/Divider/Divider"
import FormTextField from "@framework/prototypes/FormTextField"
import usePasswordFiledToggle from "@components/ui/TextField/usePasswordFiledToggle"
import { useHistory, useLocation } from "react-router-dom"
import { useStore } from "@store/index"
import { emailValidator } from "@utils/validators"
import { SignUpData } from "@store/auth/types"

import styles from "./RegisterForm.module.scss"

interface SignUpForm {
  fullName: string
  email: string
  password: string
}

interface RegisterFormProps {
  onFormSubmited: () => void
}

const fullNameValidator = yup
  .string()
  .required("Full name is required")
  .test("has-space", "Please enter both your first and last name", (value) => {
    if (!value) return false
    const nameParts = value.trim().split(/\s+/)
    return (
      nameParts.length >= 2 &&
      nameParts[0].length > 0 &&
      nameParts[1].length > 0
    )
  })

const validationSchema = yup.object().shape({
  fullName: fullNameValidator,
  email: emailValidator.clone().required("Required"),
  password: yup.string().required("Password is required"),
})

const RegisterForm: FC<RegisterFormProps> = ({ onFormSubmited }) => {
  const {
    authStore: { isAuthorized, user, error, signUp },
    userStore: { data },
  } = useStore()
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()

  const handleSubmit = async (formData: SignUpForm) => {
    const nameParts = formData.fullName.trim().split(/\s+/)
    const Name = nameParts[0]
    const Surname = nameParts.slice(1).join(" ")

    const processedData: SignUpData = {
      password: formData.password,
      email: formData.email,
      Name,
      Surname,
    }
    await signUp(processedData)
  }
  const handleLoginClick = () => {
    history.push(`/login${location.search}`)
  }

  const formik = useFormik<SignUpForm>({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  const passToggleProps = usePasswordFiledToggle()

  useEffect(() => {
    if (error) {
      alert.error(error)
    }
  }, [error])

  useEffect(() => {
    if (isAuthorized) history.push("/settings/company")
  }, [isAuthorized])

  return (
    <FormikProvider value={formik}>
      <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
        <Stack align="center" gutter="8">
          <Typography type="h1" color="dark" weight="bold">
            Create account
          </Typography>
          <Typography
            type="body1"
            color="dark"
            className={styles.signUpContainer}
          >
            Already have an account?
            <Button
              onClick={handleLoginClick}
              color="primary"
              variant="ghost"
              className={styles.button}
            >
              Sign In
            </Button>
          </Typography>
        </Stack>
        <Stack
          gutter="4"
          direction="column"
          className={styles.ssoAuthContainer}
        >
          <SSOButton variant="Google" />
          <SSOButton variant="Apple" />
          <SSOButton variant="Facebook" />
        </Stack>

        <Stack direction="row" gutter="8">
          <Divider />
          <Typography type="body2">or</Typography>
          <Divider />
        </Stack>

        <Stack
          direction="column"
          align="stretch"
          gutter="8"
          className={styles.authContainer}
        >
          <FormTextField
            label="FULL NAME"
            name="fullName"
            placeholder="Full name"
            className={styles.inputContainer}
          />
          <FormTextField
            label="EMAIL"
            name="email"
            placeholder="Email"
            className={styles.inputContainer}
          />
          <FormTextField
            label="PASSWORD"
            name="password"
            placeholder="Password"
            className={styles.inputContainer}
            {...passToggleProps}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            type="submit"
            className={styles.submitButton}
          >
            Create account
          </Button>
        </Stack>
        <Typography
          type="body2"
          color="black70Color"
          className={styles.termsText}
        >
          By creating an account using any of the options above, you agree to
          the
          <span> Terms of Use</span> & <span>Privacy Policy</span>
        </Typography>
      </form>
    </FormikProvider>
  )
}

export default RegisterForm
