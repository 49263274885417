/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { extractMonthlyAdSpend } from "@store/account-dashboard/dataTransformers"
import MonthAdSpendChart from "../MonthAdSpendChart/MonthAdSpendChart"

import styles from "./AdSpendBudget.module.scss"
import HistoricalMonthSpend from "../HistoricalMonthSpend/HistoricalMonthSpend"
import MonthSpendModal from "./MonthSpendModal"

type AdSpendBudgetProps = {
  className?: string
}

const AdSpendBudget: React.FC<AdSpendBudgetProps> = observer(
  ({ className }) => {
    const {
      accountStore: { accountId },
      accountDashboardStore: {
        monthlyPerformanceStore: {
          loadingYearPerformance,
          currYearPerf,
          prevYearPerf,
          currentYear,
          previousYear,
          loadYearPerformance,
          loadMonthlyCheckbookAmount,
        },
      },
    } = useStore()

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

    const data = useMemo(() => {
      if (currYearPerf && prevYearPerf) {
        return [
          extractMonthlyAdSpend(currYearPerf, currentYear),
          extractMonthlyAdSpend(prevYearPerf, previousYear),
        ]
      }
      return []
    }, [currYearPerf, prevYearPerf, previousYear, currentYear])

    useEffect(() => {
      if (accountId) {
        loadYearPerformance(accountId)
        loadMonthlyCheckbookAmount(accountId)
      }
    }, [accountId])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h1" color="black100Color" weight="bold">
          Ad Spend Budget
        </Typography>

        <div className={styles.wrapper}>
          <div
            onClick={() => {
              setIsOpenModal(true)
            }}
          >
            <MonthAdSpendChart />
          </div>

          <HistoricalMonthSpend />
        </div>
        <MonthSpendModal
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(false)
          }}
        />
      </div>
    )
  }
)

export default AdSpendBudget
