import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import * as yup from "yup"
import * as jwtDecode from "jwt-decode"

import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import FormTextField from "@framework/prototypes/FormTextField"
import useQueryParams from "@framework/hooks/useQueryParamsNew"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Stack from "@components/ui/Stack/Stack"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { useStore } from "@store/index"
import useValidShopifyCredentials from "@framework/hooks/useValidShopifyCredentials"
import PublicLayout from "@root/layouts/public/PublicLayout"
import { passwordValidator } from "@components/utils/validators"
import usePasswordFiledToggle from "@components/ui/TextField/usePasswordFiledToggle"

import styles from "./index.module.scss"

const validationSchema: yup.BaseSchema = yup.object({
  password: passwordValidator
    .clone()
    .trim()
    .required("Can not be empty")
    .default(""),
})

const searchParamsValidationSchema: yup.BaseSchema = yup.object({
  token: yup.string().trim().required("Can not be empty"),
})

type ChangePasswordForm = yup.InferType<typeof validationSchema>

type SearchParams = yup.InferType<typeof searchParamsValidationSchema>

const initialValues: ChangePasswordForm = validationSchema.getDefault()

const ResetPassword: React.FC = () => {
  const history = useHistory()
  const {
    userStore,
    authStore: { isAuthorized, user, isLoading, login, loginWithShopify },
  } = useStore()
  const alert = useAlert()

  const params = useQueryParams<SearchParams>(searchParamsValidationSchema)

  const handleLoginRedirect = () => history.push("/login")

  const form = useValidShopifyCredentials()

  const handleSubmit = async (form: ChangePasswordForm) => {
    const error = await userStore.resetPassword(
      params?.token ?? "",
      form.password
    )
    if (error) {
      alert.error(
        <AlertMessage title="Failed to reset password" description={error} />
      )
      return
    }
    const { email } = jwtDecode.default<{ email: string }>(params?.token ?? "")
    if (email) {
      const error = await login(email, form.password)
      if (error) {
        alert.error(
          <AlertMessage title="Failed to login" description={error} />
        )
      }
      return
    }
    handleLoginRedirect()
    alert.success(
      <AlertMessage
        title="Alright!"
        description="Your password was successfully changed"
      />
    )
  }

  useEffect(() => {
    if (!isAuthorized) return

    if (!form) {
      history.push("/settings/profile")
    }
  }, [isAuthorized, form, user])

  const formik = useFormik<ChangePasswordForm>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  const passToggleProps = usePasswordFiledToggle()

  useEffect(() => {
    if (!params?.token) history.push("/login")
  }, [params?.token])

  return (
    <PublicLayout showBackButton onBackButton={handleLoginRedirect}>
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          <Stack gutter="16" align="stretch" style={{ width: "346px" }}>
            <Typography type="h1" color="dark" weight="bold">
              Create new password
            </Typography>
            <FormTextField
              label="Password"
              name="password"
              placeholder="Password"
              {...passToggleProps}
            />

            <Button
              className={styles.control}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              Change password
            </Button>
          </Stack>
        </form>
      </FormikProvider>
    </PublicLayout>
  )
}

export default ResetPassword
