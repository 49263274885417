import React, { FC, useEffect } from "react"
import { toJS } from "mobx"
import { useStore } from "@store/index"
import MetricsCard from "@components/ui/KPIsWidget/MetricCard/MetricCard"
import { MetricNameType } from "@framework/types/metrics"

import styles from "./KPIsCards.module.scss"

interface KPIsCardsProps {
  metricOptionsValues: MetricNameType[]
}

const KPIsCards: FC<KPIsCardsProps> = ({ metricOptionsValues }) => {
  const { accountDashboardStore } = useStore()
  const { performance } = accountDashboardStore
  return (
    <div className={styles.wrapper}>
      {metricOptionsValues.map((metricName) => {
        const props = performance?.[metricName]
        return (
          <MetricsCard
            amount={props?.Value}
            trend={(props?.Change ?? 0) * 100}
            name={metricName}
            key={metricName}
          />
        )
      })}
    </div>
  )
}

export default KPIsCards
