import React, { useEffect, useMemo, useState } from "react"
import moment from "moment"

import Stack from "@components/ui/Stack/Stack"
import DatePicker from "@components/ui/DatePicker/DatePicker"
import CustomDatePicker from "./CustomDatePicker"

import styles from "./ChannelPerfomanceDatePicker.module.scss"

const ChannelPerfomanceDatePicker = () => {
  const defaultDate: [Date, Date] = useMemo(
    () => [
      moment(Date.now()).startOf("month").toDate(),
      moment(Date.now()).toDate(),
    ],
    []
  )

  const [date, setDate] = useState<Date | Date[]>(defaultDate)

  useEffect(() => {}, [])

  return (
    <Stack>
      <CustomDatePicker defValue={defaultDate} multiSelect />
    </Stack>
  )
}

export default ChannelPerfomanceDatePicker
