import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"

import { observer } from "mobx-react-lite"
import Divider from "@components/ui/Divider/Divider"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { NavOption } from "@components/ui/Tabs/types"
import Tabs from "@components/ui/Tabs/Tabs"

import styles from "./SidebarMenu.module.scss"

export const personalSettingsOptions: NavOption[] = [
  { label: "Profile settings", value: "profile", icon: "gear" },
  { label: "Password", value: "password", icon: "lock-password" },
  // { label: "Access settings", value: "access", icon: "user-star" },
]

export const companySettingsOptions: NavOption[] = [
  { label: "Company setup", value: "company", icon: "hotel" },
  // { label: "Subscription", value: "subscription", icon: "money-dollar-box" },
  {
    label: "User management",
    value: "management",
    icon: "home-gear",
  },
]

export const otherOptions: NavOption[] = [
  { label: "Contact us", value: "support", icon: "mail" },
  { label: "Privacy policy", value: "privacy", icon: "file-info" },
]

interface SidebarMenuProps {
  activeRoute?: NavOption
}

export const SidebarMenu: React.FC<SidebarMenuProps> = observer(
  ({ activeRoute }) => {
    const history = useHistory()
    const {
      authStore: { user },
    } = useStore()

    const getNavHandler = ({ value }: NavOption) => {
      history.replace(`/settings/${value}`)
    }

    const companySettingsOptions: NavOption[] = useMemo(
      () => [
        { label: "Company setup", value: "company", icon: "hotel" },
        {
          label: "User management",
          value: "management",
          icon: "home-gear",
          hidden: !user?.isOnboarded,
        },
      ],
      [user?.isOnboarded]
    )

    return (
      <Stack gutter="0" align="stretch">
        <Stack gutter="0">
          <Typography type="h3" className={styles.menuLabel}>
            Personal settings
          </Typography>
          <Tabs
            direction="vertical"
            options={personalSettingsOptions}
            currentValue={activeRoute?.value}
            onChange={getNavHandler}
            fullWidth
          />
        </Stack>

        <>
          <Divider noLine />

          <Stack gutter="0">
            <Typography type="h3" className={styles.menuLabel}>
              Company settings
            </Typography>
            <Tabs
              direction="vertical"
              options={companySettingsOptions}
              currentValue={activeRoute?.value}
              onChange={getNavHandler}
              fullWidth
            />
          </Stack>
        </>

        <Divider />

        <Stack gutter="0">
          <Tabs
            direction="vertical"
            options={otherOptions}
            currentValue={activeRoute?.value}
            onChange={getNavHandler}
            fullWidth
          />
        </Stack>
      </Stack>
    )
  }
)

export default SidebarMenu
