import {
  ComponentDescriptor,
  FCProps,
  NullableRecord,
} from "@framework/types/types"
import React from "react"

export const makeDescriptor = <
  T extends React.FC<any>,
  N = NullableRecord<FCProps<T>>
>(
  Component: T,
  props: N = {} as N
): ComponentDescriptor<T, N> => ({ Component, defaultProps: props })

export interface RenderCellOptions {
  shoulDisplayOptions: boolean
}
