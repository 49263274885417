import React, { Dispatch, SetStateAction } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { SortState } from "./Table/Table"
import RowGroup from "./Table/RowGroup"
import HeaderCell from "./Table/TableCell/HeaderCell"
import { ColumnMapper } from "./Table/types"

import styles from "./MetricsTableRow.module.scss"

interface HeaderProps<T extends object = any> {
  staticBefore?: number
  staticAfter?: number
  mapper: ColumnMapper<T>[]
  sortState: SortState
  onSort: (column: string) => void
  className?: string
  selectedCheckboxes?: string[]
  setSelectedCheckboxes?: Dispatch<SetStateAction<string[]>>
}

const Header = observer(
  <T extends object>({
    mapper,
    staticBefore = 0,
    className,
    sortState,
    onSort,
  }: HeaderProps<T>) => {
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")

    const beforeMapper = mapper.slice(0, staticBefore)
    const middleMapper = mapper.slice(staticBefore, mapper.length)

    const renderCell = (mapper: ColumnMapper<T>) => (
      <div className={clsx(styles.cell, styles.headerCell)}>
        <HeaderCell
          label={mapper.label}
          name={mapper.name as string}
          isSortable={false}
          sortState={sortState}
          onSort={onSort}
          className={styles.headerCellInner}
        />
      </div>
    )

    return (
      <div className={clsx(styles.header, className)}>
        <RowGroup
          name="static"
          mapper={beforeMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderCell}
        />
        <RowGroup
          name="main"
          mapper={middleMapper}
          className={styles.group}
          renderCell={renderCell}
        />
      </div>
    )
  }
)

export default Header
