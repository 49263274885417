import React, { FC } from "react"
import { ScrollSyncPane } from "react-scroll-sync"
import Typography from "@components/ui/Typography/Typography"
import { metricNamesListV1 } from "@framework/types/metrics"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { TableData } from "./utils"
import styles from "./ChannelPerfomanceDetails.module.scss"

interface MetricsStatisticGridProps {
  data?: TableData["data"]
}

const MetricsStatisticGrid: FC<MetricsStatisticGridProps> = ({ data }) => (
  <ScrollSyncPane>
    <div
      className={styles.metricsGridContainer}
      style={{ gridTemplateColumns: `repeat(${data?.length},6.5rem)` }}
    >
      {data?.map((el) => (
        <div>
          {metricNamesListV1.map((metricName) => {
            const metric = getMetricDescriptor(metricName)
            return (
              <Typography type="body1" className={styles.metricItem}>
                {metric.formatter(el.Metrics[metricName].Value)}
              </Typography>
            )
          })}
        </div>
      ))}
    </div>
  </ScrollSyncPane>
)

export default MetricsStatisticGrid
