import {
  MetricNameTypeV1,
  MetricType,
  PerformanceReportV1,
  SummaryMetricNameType,
} from "@framework/types/metrics"
import {
  ADSAnalyticsGroupType,
  ADSAnalyticsSummaryGroupType,
} from "@services/creative-analytics"
import { MetaCampaignStatus } from "@pages/MetaAdsChannel/types"

export type AnalyticsCard = {
  [key: string]: string | MetricType
  Name: string
  Id: string
  AdType: string
  MediaURL: string
  CampaignName: string
  AdsetName: string
  Type: "IMAGE" | "VIDEO" | "CAROUSEL"
  Status: MetaCampaignStatus
  VideoURL: string
  ThumbnailURL: string
  CreatedAt: string
  PageURL: string
  VideoSource: "instagram_raw" | "facebook_plugin"
} & PerformanceReportV1

export type AnalyticsFilter = { key: 1 | 2 | 3; value: string }

export const filterEqualityTypes: Record<string, string> = {
  equals: "Equals",
  notEqual: "Not equal",
  greaterThan: "Greater than",
  greaterThanOrEqual: "Greater than or equal",
  lessThan: "Less than",
  lessThanOrEqual: "Less than or equal",
}

const filterEqualityTypesFunctions: Record<
  string,
  (a: number, b: number) => boolean
> = {
  equals: (a: number, b: number) => a === b,
  notEqual: (a: number, b: number) => a !== b,
  greaterThan: (a: number, b: number) => a > b,
  lessThan: (a: number, b: number) => a < b,
  greaterThanOrEqual: (a: number, b: number) => a >= b,
  lessThanOrEqual: (a: number, b: number) => a <= b,
}
export function filterEqualityMapper(type: keyof typeof filterEqualityTypes) {
  return filterEqualityTypesFunctions[type]
}

export const filterIncludesTypes: Record<string, string> = {
  includes: "Includes",
  notInclude: "Does not include",
}

export const creativeTypes: Record<string, string> = {
  image: "IMAGE",
  video: "VIDEO",
  carousel: "CAROUSEL",
}

export type ExtendedMetricNameTypeV1 =
  | MetricNameTypeV1
  | "campaigns"
  | "adSets"
  | "creativeType"
  | "name"
export const filterOptions: Record<ExtendedMetricNameTypeV1, string> = {
  name: "Ad Name",
  campaigns: "Campaigns",
  adSets: "Ad Sets",
  creativeType: "Creative Type",
  AOV: "Average Order Value",
  Clicks: "Clicks",
  Impressions: "Impressions",
  Conversions: "Conversions",
  CostConv: "Cost/Conversion",
  ConversionRate: "Conversion Rate %",
  Revenue: "Revenue",
  Ctr: "Click Through Rate (Links)",
  AverageCpc: "Cost Per Click (CPC)",
  Cpm: "Cost Per Mille (CPM)",
  Roas: "ROAS (Return on Ad Spend)",
  Cost: "Cost",
  Thumbstop: "Thumbstop",
  Holdplay: "Holdplay",
}

export const defaultMetrics = [
  "AOV",
  "Clicks",
  "Impressions",
  "Conversions",
  "CostConv",
  "ConversionRate",
  "Revenue",
  "Ctr",
  "Cost",
  "Roas",
]
export type DefaultMetricsType = (typeof defaultMetrics)[number]
export const metricOptions: Record<MetricNameTypeV1, string> = {
  AOV: "Average Order Value",
  Clicks: "Clicks",
  Impressions: "Impressions",
  Conversions: "Conversions",
  CostConv: "Cost/Conversion",
  ConversionRate: "Conversion Rate %",
  Revenue: "Revenue",
  Ctr: "Click Through Rate (Links)",
  AverageCpc: "Cost Per Click (CPC)",
  Cpm: "Cost Per Mille (CPM)",
  Roas: "ROAS (Return on Ad Spend)",
  Cost: "Cost",
  Thumbstop: "Thumbstop",
  Holdplay: "Holdplay",

  // field15Sec3SecVideoRetention: "15 Sec/3 Sec Video Retention",
  // adName: "Ad Name",
  // adSetName: "Ad Set Name",
  // addToCartToPurchaseRatio: "Add-To-Cart To Purchase Ratio",
  // addToCarts: "Add-To-Carts",
  // appInstalls: "App Installs",
  // campaignName: "Campaign Name",
  // clickThroughRateAll: "Click Through Rate (All)",
  // clickToAddToCartRatio: "Click To Add-To-Cart Ratio",
  // clickToPurchaseRatio: "Click To Purchase Ratio",
  // costPerAddToCarts: "Cost Per Add-To-Carts",
  // costPerAppInstall: "Cost Per App Install",
  // costPerHoldplay: "Cost Per Holdplay",
  // costPerLead: "Cost Per Lead",
  // costPerLinkClick: "Cost Per Link Click",
  // costPerOutboundClick: "Cost Per Outbound Click",
  // costPerPixel: "Cost Per Pixel",
  // costPerPurchase: "Cost Per Purchase",
  // costPerThruplay: "Cost Per Thruplay",
  // costPerUniqueOutboundClick: "Cost Per Unique Outbound Click",
  // costPerUserRegistered: "Cost Per User Registered",
  // costPerVideoThruplay: "Cost Per Video Thruplay",
  // createdAt: "Created At",
  // endAt: "End At",
  // engagement: "Engagement",
  // frequency: "Frequency",
  // holdRate: "Hold Rate",
  // interest: "Interest",
  // leads: "Leads",
  // linkClicks: "Link Clicks",
  // outboundClicks: "Outbound Clicks",
  // purchases: "Purchases",
  // reach: "Reach",
  // startAt: "Start At",
  // thruplay: "Thruplay",
  // thruplayClickThroughRate: "Thruplay Click Through Rate",
  // thumbstopRatio: "Thumbstop Ratio",
  // updatedAt: "Updated At",
  // userRegistered: "User Registered",
  // video100WatchedViews: "Video 100% Watched Views",
  // video25WatchedViews: "Video 25% Watched Views",
  // video30sWatchedViews: "Video 30s Watched Views",
  // video50WatchedViews: "Video 50% Watched Views",
  // video75WatchedViews: "Video 75% Watched Views",
  // video95WatchedViews: "Video 95% Watched Views",
  // videoAvgTimeWatched: "Video Avg Time Watched",
  // videoPlays: "Video Plays",
  // adDelivery: "Ad Delivery",
  // clickThroughRateLinks: "Click Through Rate (Links)",
  // costPerClickCPC: "Cost Per Click (CPC)",
  // costPerMilleCPM: "Cost Per Mille (CPM)",
  // roasReturnOnAdSpend: "ROAS (Return on Ad Spend)",
}
export interface DropOffHistogramEntity {
  Timeframe: string
  DropOff: number
  Percentage: number
}

export const groupByObjectOptions: Record<
  ADSAnalyticsGroupType | "None",
  string
> = {
  None: "None",
  ByTitle: "By Title",
  ByName: "By Name",
  ByCreative: "By Creative",
}

export const summaryGroupByObjectOptions: Record<
  ADSAnalyticsSummaryGroupType,
  string
> = {
  AllAds: "All ads",
  VideoAds: "Video ads",
  PhotoAds: "Photo ads",
}
