import React, { FC } from "react"
import IconButton from "@components/ui/Button/IconButton"
import Icon from "@components/ui/Icon/Icon"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import GoogleAdsIcon from "@root/assets/svgIcons/GoogleAdsIcon"
import FacebookIcon from "@root/assets/svgIcons/FacebookIcon"

import MonthAdSpendChart from "../MonthAdSpendChart/MonthAdSpendChart"
import styles from "./AdSpendBudget.module.scss"
import SimpleMetricTable from "../SimpleMetricTable/SimpleMetricTable"

interface MonthSpendModalProps {
  isOpen: boolean
  onClose: () => void
}

const mockData = [
  [
    <Stack direction="row" gutter="8">
      <GoogleAdsIcon />
      <Typography type="h4" weight="semibold">
        GoogleAds
      </Typography>
    </Stack>,
    <Typography type="body1" weight="normal">
      27.11
    </Typography>,
    <Typography type="body1" weight="normal">
      $100
    </Typography>,
  ],
  [
    <Stack direction="row" gutter="8">
      <FacebookIcon />
      <Typography type="h4" weight="semibold">
        Google Ads
      </Typography>
    </Stack>,
    <Typography type="body1" weight="normal">
      27.11
    </Typography>,
    <Typography type="body1" weight="normal">
      $100
    </Typography>,
  ],
  [
    <Typography type="h4" weight="semibold" color="black100Color">
      Total
    </Typography>,
    <Typography type="body1" weight="normal">
      27.11
    </Typography>,
    <Typography type="body1" weight="normal">
      $100
    </Typography>,
  ],
]

const TABLE_HEADER = ["Channel", "Current spend", "Projected spend"]

const MonthSpendModal: FC<MonthSpendModalProps> = ({ isOpen, onClose }) => (
  <Modal hideHeader className={styles.modalRoot} isOpen={isOpen}>
    <IconButton className={styles.closeIcon}>
      <Icon name="cross" onClick={onClose} />
    </IconButton>
    <Stack direction="row" align="center" gutter="24">
      <MonthAdSpendChart className={styles.chartContainer} />
      <div className={styles.horizontalDivider} />
      <div className={styles.tableContainer}>
        <SimpleMetricTable tableHeaders={TABLE_HEADER} data={mockData} />
      </div>
    </Stack>
  </Modal>
)

export default MonthSpendModal
