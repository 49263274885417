import React, { useEffect, useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import { useStore } from "@store/index"
import { extractMonthlyAdSpend } from "@store/account-dashboard/dataTransformers"
import GoogleMonthAdSpendChart from "../GoogleMonthAdSpendChart/GoogleMonthAdSpendChart"

import styles from "./GoogleAdSpendBudget.module.scss"
import GoogleHostoricalMonthlyAdSpend from "../GoogleHostoricalMonthlyAdSpend/GoogleHostoricalMonthlyAdSpend"

type GoogleAdSpendBudgetProps = {
  className?: string
}

const GoogleAdSpendBudget: React.FC<GoogleAdSpendBudgetProps> = observer(
  ({ className }) => {
    const {
      accountStore: { accountId, account },
      accountDashboardStore: {
        monthlyPerformanceStore: {
          currYearPerf,
          prevYearPerf,
          currentYear,
          previousYear,
          loadYearPerformance,
          loadMonthlyCheckbookAmount,
        },
      },
    } = useStore()

    const data = useMemo(() => {
      if (currYearPerf && prevYearPerf) {
        return [
          extractMonthlyAdSpend(currYearPerf, currentYear),
          extractMonthlyAdSpend(prevYearPerf, previousYear),
        ]
      }
      return []
    }, [currYearPerf, prevYearPerf, previousYear, currentYear])

    useEffect(() => {
      if (accountId && account?.isConnected) {
        loadYearPerformance(accountId)
        loadMonthlyCheckbookAmount(accountId)
      }
    }, [accountId, account?.isConnected])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type="h1" color="black100Color" weight="bold">
          Ad Spend Budget
        </Typography>

        <div className={styles.wrapper}>
          <GoogleMonthAdSpendChart />

          <GoogleHostoricalMonthlyAdSpend dataset={data} />
        </div>
      </div>
    )
  }
)

export default GoogleAdSpendBudget
