import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import Stack from "@components/ui/Stack/Stack"

import styles from "./SignUp.module.scss"
import RegisterForm from "./components/RegisterForm"
import CheckInbox from "./components/CheckInbox"

const MAIN_TITLE = "Intelligent Feed Management & Paid Ads Management"
const SUB_TITLE =
  "Automate your entire Google and Meta Ads process all in one platform"
const insight_list = [
  "Real-Time Inventory to Social Ad Sync",
  "Dynamic Ad Activation & Alerts",
  "Social Creative Analytics Reporting",
  "Intelligent Feed Optimization",
  "Auto Campaign Creation, Ad Management, and Monitoring",
  "AI Keyword Research and Analysis",
]
interface SignUpProps {}

const SignUp: React.FC<SignUpProps> = () => {
  const [isFormSubmited, setIsFormSubmit] = useState<boolean>(false)

  return (
    <Stack
      direction="row"
      align="center"
      justify="space-between"
      gutter="32"
      className={styles.root}
    >
      <Typography type="h1" color="primary" className={styles.logo}>
        ADORO
      </Typography>
      {!isFormSubmited ? (
        <RegisterForm
          onFormSubmited={() => {
            setIsFormSubmit(true)
          }}
        />
      ) : (
        <CheckInbox
          onResendLink={() => {}}
          onBackClick={() => {
            setIsFormSubmit(false)
          }}
        />
      )}

      <section className={styles.content}>
        <Typography type="h1" weight="bold" color="light">
          {MAIN_TITLE}
        </Typography>
        <Typography type="h2" weight="semibold" color="light">
          {SUB_TITLE}
        </Typography>

        <ul className={styles.list}>
          {insight_list.map((text) => (
            <li key={text}>
              <Icon name="checkbox-circle-fill" /> {text}
            </li>
          ))}
        </ul>
      </section>
    </Stack>
  )
}

export default observer(SignUp)
