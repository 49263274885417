import { MetricNameType } from "@framework/types/metrics"
import React, { FC } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { CheckboxItem } from "../OverallKPIs/utils"
import MetricItem from "./MetricItem"

import styles from "./MetricsSideBar.module.scss"

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

interface DragabbleListProps {
  onSelectItem: (value: MetricNameType) => void
  metrics: CheckboxItem[]
  setMetrics: React.Dispatch<React.SetStateAction<CheckboxItem[]>>
}

const DraggableList: FC<DragabbleListProps> = ({
  metrics,
  onSelectItem,
  setMetrics,
}) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }
    const newItems = reorder(
      metrics,
      result.source.index,
      result.destination.index
    )
    setMetrics(newItems as CheckboxItem[])
  }

  return (
    <div className="draggable-list-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {metrics.map((item, index) => (
                <Draggable
                  key={item.name}
                  draggableId={item.name}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      style={styles}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MetricItem
                        isCheked={item.checked}
                        label={item.label}
                        onToogle={() => {
                          onSelectItem(item.name)
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default DraggableList
