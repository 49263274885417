import React from "react"
import { MetricDescription, MetricsTrendType } from "@framework/types/metrics"
import clsx from "clsx"
import Typography from "@components/ui/Typography/Typography"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { mbNoData, percentageFormatter } from "@services/utils"
import Trend from "@components/ui/Typography/Trend/Trend"
import { ViewCellProps } from "../types"
import { RenderCellOptions } from "../utils"

import styles from "./TableCell.module.scss"

export interface MetricCellProps extends ViewCellProps<any> {
  getMetric: (name: string) => MetricDescription
  metricsTrend: MetricsTrendType
  options: RenderCellOptions
}

export const MetricCell: React.FC<MetricCellProps> = React.memo(
  ({ name, data, getMetric, metricsTrend, options }) => {
    const value = data?.[name]
    const metric = getMetric(name)
    const trendFormatter = metric.trendFormat ?? positiveTransformDirection

    const metricChange = value.Change || 0

    return (
      <div className={clsx(styles.metricCell)}>
        <Typography type="body1" className={styles.metric}>
          <span>{mbNoData(value?.Value, metric.formatter)}</span>
          {options?.shoulDisplayOptions && (
            <>
              <Typography color="black60Color">
                {mbNoData(value?.Value, metric.formatter)}
              </Typography>
              {!!metricChange && (
                <Trend type="h5" {...trendFormatter(value.Change)}>
                  {percentageFormatter((value.Change || 0) * 100)}
                </Trend>
              )}
            </>
          )}
        </Typography>
      </div>
    )
  }
)

export default MetricCell
