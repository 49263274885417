import React, { useCallback, useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { metricsDescriptors } from "@framework/constants/metrics"
import useToggle from "@framework/hooks/useToggle"
import { useAlert } from "react-alert"
import Loader from "@components/ui/Loader/Loader"
import {
  AnalyticsCard,
  AnalyticsFilter,
  filterEqualityMapper,
  filterIncludesTypes,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import {
  MetricsTrendType,
  MetricDescription,
  MetricType,
} from "@framework/types/metrics"
import KPITrendModal from "@pages/MetaAdsChannel/components/KPITrendChart/KPITrendModal"
import { apiDateFormatter } from "@services/utils"
import { PerformanceReportPeriodicity } from "@framework/types/dashboard"
import { calculateMetricsTrend } from "@pages/MetaAdsChannel/components/utils"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Chip from "@components/ui/Chip/Chip"
import Typography from "@components/ui/Typography/Typography"
import {
  ADSAnalyticsGroupTypeEntity,
  ADSAnalyticsResponse,
  ReportEntitySettings,
} from "@services/creative-analytics"
import useInfiniteScroll from "@framework/hooks/useInfiniteScroll"
import CreateReportModal from "@pages/MetaAdsChannel/components/Modals/CreateReportModal"
import EditReportModal from "@pages/MetaAdsChannel/components/Modals/EditReportModal"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import VideoPreviewModal from "@pages/MetaAdsChannel/components/Modals/VIdeoPreviewModal"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import { DatePickerValue } from "@store/reports/types"
import GroupCard from "@pages/MetaAdsChannel/components/ReportCard/GroupCard"
import GroupsSidebar from "@pages/MetaAdsChannel/components/GroupsSIdebar/GroupsSidebar"
import useCAFilters from "@framework/hooks/useCAFilters"
import useLocalPagination from "@framework/hooks/useLocalPagination"
import ReportControlPanel from "../ReportControlPanel"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignStatus, ViewType } from "../types"
import mapper from "./mapper"
import Table from "../components/Table/Table"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"

import styles from "./General.module.scss"

type GeneralPageProps = {
  className?: string
}

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const GeneralPage: React.FC<GeneralPageProps> = observer(({ className }) => {
  const {
    accountStore: { accountId },
    analyticsFiltersStore: {
      activePeriodicity,
      filtersStatus,
      setFiltersStatus,
      setActivePeriodicity,
    },
    creativeAnalyticsStore: {
      adsCardsData,
      KPITrendsData,
      reportData,
      getKPITrends,
      createReport,
      updateReport,
      getReportsList,
      isLoading,
      isADSCardsLoading,
      isKPILoading,
      isReportLoading,
    },
  } = useStore()

  const alert = useAlert()

  // eslint-disable-next-line no-empty-pattern
  const [allFilters, setAllFilters, {}, setActiveMetrics] = useCAFilters(
    reportData?.id ? String(reportData?.id) : "General"
  )

  const {
    view,
    filters,
    groupingType,
    sortByMetric,
    period,
    sortColorMetrics,
  } = allFilters

  const [filteredCards, setFilteredCards] = React.useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )

  const [filteredGroups, setFilteredGroups] = useState<
    ADSAnalyticsResponse["data"]["Groupings"]
  >(adsCardsData.Groupings || [])

  const [metricsTrend, setMetricsTrend] = useState<MetricsTrendType>({})
  const [report, setReport] = useState<{
    id: string
    name: string
    status: MetaCampaignStatus
  } | null>(null)

  useEffect(() => {
    setFilteredGroups(adsCardsData.Groupings)
  }, [adsCardsData.Groupings, report?.id])

  const [showColorMetricsSelect, setShowColorMetricsSelect] = useState(false)
  const [reportDetails, setReportDetails] = useState<AnalyticsCard>()
  const [activeGroup, setActiveGroup] =
    useState<ADSAnalyticsGroupTypeEntity | null>(null)
  const [isGroupsSidebarOpen, setIsGroupsSidebarOpen] = useState(false)
  const [activeGroupAds, setActiveGroupAds] = useState<AnalyticsCard[]>([])

  const {
    data: paginationData,
    next,
    reset,
  } = useLocalPagination(filteredCards, 30)

  useEffect(() => {
    if (reset && filteredCards.length) reset()
  }, [filteredCards, reset, view])

  useInfiniteScroll(next)

  const filtersRef = React.useRef(filters)

  const modal = useToggle()
  const KPImodal = useToggle()
  const createReportModal = useToggle()
  const editReportModal = useToggle()
  const videoPreviewModal = useToggle()

  const metricsOptions = useOptionSearchList({
    list: metricsOptionsArray ?? [],
  })

  const onAppliedFilters = (filters: AnalyticsFilter[][]) => {
    if (filters.length === 0) {
      setFilteredCards(adsCardsData.AdCreatives)
      setFilteredGroups(adsCardsData.Groupings)
      setAllFilters({ ...allFilters, filters: [] })
      filtersRef.current = []
      return
    }
    setAllFilters({ ...allFilters, filters })
  }

  const onMetricsFilterChange = (metrics: string[]) => {
    const filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      metrics.includes(md.name)
    )
    setActiveMetrics(filteredMetricsDescriptors)
  }

  const handleOpenKPItrendModal = (
    id: string,
    name: string,
    status: MetaCampaignStatus
  ) => {
    setReport({ id, name, status: status || "INACTIVE" })
    KPImodal.setOpened(true)
  }

  const handleOpenReportDetailsModal = (card: AnalyticsCard) => {
    setReportDetails(card)
    modal.handleToggle()
  }

  const handleActivePeriodicity = (
    periodicity: PerformanceReportPeriodicity
  ) => {
    setActivePeriodicity(periodicity)
  }

  const handleMetricsOptions = (value: string) => {
    if (filtersStatus === "reportView") {
      setFiltersStatus("reportEditing")
    }
    let colorMetrics = [...allFilters.sortColorMetrics]
    if (colorMetrics.find((it) => it === value)) {
      colorMetrics = colorMetrics.filter((it) => it !== value)
    } else {
      colorMetrics = [...colorMetrics, value]
    }
    setAllFilters({
      ...allFilters,
      sortColorMetrics: [...colorMetrics],
    })

    const newMetric: MetricDescription = metricsDescriptors.find(
      (md) => md.name === value
    ) as MetricDescription
    let filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      allFilters.activeMetrics.some(
        (activeMetric) => activeMetric.name === md.name
      )
    )
    if (!filteredMetricsDescriptors.find((it) => it.name === newMetric?.name)) {
      filteredMetricsDescriptors = [...filteredMetricsDescriptors, newMetric]
    }
    setActiveMetrics(filteredMetricsDescriptors)
  }

  useEffect(() => {
    console.log(toJS(allFilters.period))
  }, [allFilters])

  const handleRemoveColorMetric = (filter: string) => {
    if (filtersStatus === "reportView") {
      setFiltersStatus("reportEditing")
    }
    const colorMetrics = [...allFilters.sortColorMetrics].filter(
      (it) => it !== filter
    )
    setAllFilters({
      ...allFilters,
      sortColorMetrics: [...colorMetrics],
    })
  }

  const onCreateReport = async (name: string) => {
    const rangeType = period.value === "custom" ? "static" : "relative"
    const resultFilters = filters.map((filter) => {
      const res: {
        name: string
        comparator: string
        value: string
      }[] = []
      const valuesArray = filter
        .filter((it) => it.key === 3)
        .map((it) => it.value)
      if (valuesArray.length && valuesArray.length > 1) {
        valuesArray.forEach((value) =>
          res.push({
            name: filter[0].value,
            comparator: filter[1].value,
            value,
          })
        )
        return res
      }
      return [
        {
          name: filter[0].value,
          comparator: filter[1].value,
          value: filter[2].value,
        },
      ]
    })
    const report: ReportEntitySettings = {
      name,
      time_period: {
        start_date: apiDateFormatter(period.range[0]),
        end_date: apiDateFormatter(period.range[1]),
        range_type: rangeType,
        range_value: period.value as DatePickerValue,
      },
      filters: resultFilters.flat(),
      group: groupingType,
      metrics: allFilters.activeMetrics.map((metric) => metric.name),
      view_mode: view,
      sort_option: sortByMetric
        ? {
            type: sortByMetric.value,
            order: sortByMetric.order,
          }
        : null,
      color_sort_options: sortColorMetrics,
    }
    if (accountId) {
      await createReport(accountId, report).then((res) => {
        if (!res) {
          createReportModal.setOpened(false)
          alert.success("Report created successfully")
          getReportsList(accountId)
        }
      })
    }
  }

  const onEditReport = async () => {
    if (reportData && accountId) {
      const rangeType = period.value === "custom" ? "static" : "relative"
      const resultFilters = filters.map((filter) => {
        const res: {
          name: string
          comparator: string
          value: string
        }[] = []
        const valuesArray = filter
          .filter((it) => it.key === 3)
          .map((it) => it.value)
        if (valuesArray.length && valuesArray.length > 1) {
          valuesArray.forEach((value) =>
            res.push({
              name: filter[0].value,
              comparator: filter[1].value,
              value,
            })
          )
          return res
        }
        return [
          {
            name: filter[0].value,
            comparator: filter[1].value,
            value: filter[2].value,
          },
        ]
      })
      const report: ReportEntitySettings = {
        name: reportData?.settings.name,
        time_period: {
          start_date: apiDateFormatter(period.range[0]),
          end_date: apiDateFormatter(period.range[1]),
          range_type: rangeType,
          range_value: period.value as DatePickerValue,
        },
        filters: resultFilters.flat(),
        group: groupingType,
        metrics: allFilters.activeMetrics.map((metric) => metric.name),
        view_mode: view,
        sort_option: sortByMetric
          ? {
              type: sortByMetric.value,
              order: sortByMetric.order,
            }
          : null,
        color_sort_options: sortColorMetrics,
      }
      await updateReport(accountId, reportData.id, report).then((res) => {
        if (!res) {
          editReportModal.setOpened(false)
          alert.success("Report successfully updated")
          getReportsList(accountId)
        }
      })
      setFiltersStatus("reportView")
    }
  }

  const handleOpenVideoPreview = (card: AnalyticsCard) => {
    setReportDetails(card)
    videoPreviewModal.setOpened(true)
  }

  const handlePreviousDetailsCard = (cardId: string) => {
    if (groupingType === "None") {
      const currentIndex = filteredCards.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let prevoriusCard: AnalyticsCard = filteredCards[currentIndex - 1]
      if (currentIndex === 0) {
        prevoriusCard = filteredCards[filteredCards.length - 1]
      }
      setReportDetails(prevoriusCard)
    } else {
      const cardsFilteredByCurrentActiveGroup = filteredCards.filter((card) =>
        activeGroup?.Ads.includes(card.Id)
      )
      if (cardsFilteredByCurrentActiveGroup.length === 1) return
      const currentIndex = cardsFilteredByCurrentActiveGroup.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let prevoriusCard: AnalyticsCard =
        cardsFilteredByCurrentActiveGroup[currentIndex - 1]
      if (currentIndex === 0) {
        prevoriusCard =
          cardsFilteredByCurrentActiveGroup[
            cardsFilteredByCurrentActiveGroup.length - 1
          ]
      }
      setReportDetails(prevoriusCard)
    }
  }

  const handleNextDetailsCard = (cardId: string) => {
    if (groupingType === "None") {
      const currentIndex = filteredCards.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let nextCard: AnalyticsCard = filteredCards[currentIndex + 1]
      if (currentIndex === filteredCards.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        nextCard = filteredCards[0]
      }
      setReportDetails(nextCard)
    } else {
      const cardsFilteredByCurrentActiveGroup = filteredCards.filter((card) =>
        activeGroup?.Ads.includes(card.Id)
      )
      if (cardsFilteredByCurrentActiveGroup.length === 1) return
      const currentIndex = cardsFilteredByCurrentActiveGroup.findIndex(
        (it: AnalyticsCard) => it.Id === cardId
      )
      let nextCard: AnalyticsCard =
        cardsFilteredByCurrentActiveGroup[currentIndex + 1]
      if (currentIndex === cardsFilteredByCurrentActiveGroup.length - 1) {
        // eslint-disable-next-line prefer-destructuring
        nextCard = cardsFilteredByCurrentActiveGroup[0]
      }
      setReportDetails(nextCard)
    }
  }

  const calculateTotalCardsWithinGroup = useCallback(
    (group: ADSAnalyticsGroupTypeEntity) => {
      const cards = filteredCards.filter((card) => group.Ads.includes(card.Id))
      return cards?.length || 0
    },
    [filteredCards]
  )

  const handleGroupClick = (group: ADSAnalyticsGroupTypeEntity) => {
    setActiveGroup(group)
    setIsGroupsSidebarOpen(true)
    const groupAds = filteredCards.filter((card) =>
      group?.Ads.includes(card.Id)
    )
    setActiveGroupAds(groupAds)
  }

  useEffect(() => {
    if (reportData && !reportData.is_read_only && filtersStatus === "base") {
      setFiltersStatus("reportView")
    }
    if (!reportData) {
      setFiltersStatus("base")
    }
  }, [reportData, adsCardsData])

  useEffect(() => {
    let newPeriod: DateRangeOption = {
      label: period.label,
      value: period.value,
      range: period.range,
    }
    if (reportData && adsCardsData) {
      if (
        reportData?.settings.time_period.start_date &&
        reportData.settings.time_period.range_type === "static" &&
        filtersStatus !== "reportEditing"
      ) {
        newPeriod = {
          label: reportData?.settings.time_period.range_type,
          value: reportData?.settings.time_period.range_value,
          range: [
            new Date(reportData.settings.time_period.start_date),
            new Date(reportData.settings.time_period.end_date),
          ],
        }
      } else if (
        reportData?.settings.time_period.start_date &&
        reportData.settings.time_period.range_type === "relative" &&
        filtersStatus !== "reportEditing"
      ) {
        const from = new Date()
        const to = new Date()
        switch (reportData.settings.time_period.range_value) {
          case "one_week":
            from.setDate(to.getDate() - 7)
            break
          case "two_weeks":
            from.setDate(to.getDate() - 14)
            break
          case "month":
            from.setDate(to.getDate() - 31)
            break
          default:
        }
        newPeriod = {
          label: reportData?.settings.time_period.range_type,
          value: reportData?.settings.time_period.range_value,
          range: [from, to],
        }
      }

      if (reportData?.settings.metrics.length && allFilters.isFirstInit) {
        const appliedMetrics = metricsDescriptors.filter((md) =>
          reportData?.settings.metrics.includes(md.name)
        )
        setActiveMetrics(appliedMetrics)
      }

      const filtersArray: AnalyticsFilter[][] = []
      const mainFilters = new Map()
      if (reportData?.settings?.filters?.length) {
        let filterNumber = 1
        reportData?.settings.filters.forEach((it, i) => {
          if (i === 0) {
            mainFilters.set(filterNumber, [
              { key: 1, value: it.name },
              { key: 2, value: it.comparator },
              { key: 3, value: it.value },
            ])
          } else if (
            mainFilters.has(filterNumber) &&
            i !== 0 &&
            it.name === mainFilters.get(filterNumber)[0].value &&
            it.comparator === mainFilters.get(filterNumber)[1].value
          ) {
            mainFilters.get(filterNumber).push({ key: 3, value: it.value })
          } else if (
            it.name !== mainFilters.get(filterNumber)[0].value ||
            it.comparator !== mainFilters.get(filterNumber)[1].value
          ) {
            filterNumber += 1
            mainFilters.set(filterNumber, [
              { key: 1, value: it.name },
              { key: 2, value: it.comparator },
              { key: 3, value: it.value },
            ])
          }
        })
      }
      mainFilters.forEach((value, key) => {
        filtersArray.push(value)
      })
      if (allFilters.isFirstInit) {
        setAllFilters({
          ...allFilters,
          view:
            (reportData?.settings.view_mode.toLowerCase() as ViewType) ||
            "card",
          filters: filtersArray,
          groupingType: reportData?.settings.group
            ? reportData.settings.group
            : "None",
          sortByMetric: reportData?.settings?.sort_option?.type
            ? {
                value: reportData.settings.sort_option.type,
                order: reportData.settings.sort_option.order,
              }
            : {
                value: allFilters.sortByMetric.value,
                order: allFilters.sortByMetric.order,
              },
          period: newPeriod,
          sortColorMetrics: reportData?.settings?.color_sort_options || [],
          isFirstInit: false,
        })
      }
    }
  }, [reportData, adsCardsData])

  useEffect(() => {
    const res = calculateMetricsTrend(
      adsCardsData.AdCreatives,
      sortColorMetrics
    )
    setMetricsTrend(res)
  }, [adsCardsData, sortColorMetrics])

  useEffect(() => {
    if (!accountId || !report) return
    getKPITrends(accountId, report.id, {
      from: apiDateFormatter(period.range[0]),
      to: apiDateFormatter(period.range[1]),
      periodicity: activePeriodicity,
    }).then((res) => {
      if (res) alert.error(res)
    })
  }, [report, activePeriodicity, allFilters])

  useEffect(() => {
    let result: AnalyticsCard[]
    if (reportData) {
      result = adsCardsData.AdCreatives
    } else {
      result =
        filteredCards.length && filters.length
          ? filteredCards
          : adsCardsData.AdCreatives
    }

    let resultGroups =
      groupingType !== "None" && filteredGroups[groupingType] && filteredGroups
        ? filteredGroups[groupingType]
        : []

    if (filtersRef.current.length > filters.length) {
      filtersRef.current = filters
      result = adsCardsData.AdCreatives
      resultGroups =
        groupingType !== "None" && adsCardsData.Groupings[groupingType]
          ? adsCardsData.Groupings[groupingType]
          : []
    }

    if (filters.length) {
      filters.forEach((filter) => {
        const firstFilter = filter[0]
        const secondFilter = filter[1]
        if (
          firstFilter.value === "campaigns" ||
          firstFilter.value === "adSets" ||
          firstFilter.value === "creativeType" ||
          firstFilter.value === "name"
        ) {
          const thirdFilters = filter.filter((f) => f.key === 3)
          if (filterIncludesTypes[secondFilter.value] === "Includes") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return thirdFilters.find((f) => f.value === card.AdsetName)
              if (firstFilter.value === "name")
                return thirdFilters.find((f) => f.value === card.Name)
              return thirdFilters.find((f) => f.value === card.Type)
            })
          }
          if (filterIncludesTypes[secondFilter.value] === "Does not include") {
            result = result.filter((card) => {
              if (firstFilter.value === "campaigns")
                return !thirdFilters.find((f) => f.value === card.CampaignName)
              if (firstFilter.value === "adSets")
                return !thirdFilters.find((f) => f.value === card.AdsetName)
              if (firstFilter.value === "name")
                return !thirdFilters.find((f) => f.value === card.Name)
              return !thirdFilters.find((f) => f.value === card.Type)
            })
          }
        } else {
          const firstFilterValue = firstFilter.value
          const secondFilterValue = secondFilter.value
          const thirdFilterValue = Number(filter[2].value)
          const filterEqualityMapperType =
            filterEqualityMapper(secondFilterValue)
          result = result.filter((card) => {
            const metric = card[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
          resultGroups = resultGroups.filter((card) => {
            // @ts-ignore
            const metric = card.Performance[firstFilterValue]
            if (typeof metric === "object") {
              return filterEqualityMapperType(metric.Value, thirdFilterValue)
            }
            return false
          })
        }
      })
    }
    filtersRef.current = filters

    if (sortByMetric?.value && sortByMetric?.order) {
      const cards = [...result]
      const groups: ADSAnalyticsGroupTypeEntity[] =
        groupingType !== "None" && filteredGroups ? [...resultGroups] : []

      cards.sort((a, b) => {
        const aMetric = a[sortByMetric.value] as MetricType
        const bMetric = b[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      groups.sort((a, b) => {
        // @ts-ignore
        const aMetric = a.Performance?.[sortByMetric.value] as MetricType
        // @ts-ignore
        const bMetric = b.Performance?.[sortByMetric.value] as MetricType
        const order = sortByMetric.order === "asc" ? 1 : -1
        return (aMetric.Value - bMetric.Value) * order
      })

      setFilteredGroups((prevState) => ({
        ...prevState,
        [groupingType]: groups,
      }))
      setFilteredCards(cards)
    } else {
      setFilteredCards(result)
      if (groupingType === "None") {
        setFilteredGroups(adsCardsData.Groupings)
      } else {
        setFilteredGroups((prevState) => ({
          ...prevState,
          [groupingType]: resultGroups,
        }))
      }
    }
  }, [adsCardsData, reportData, allFilters])

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanel
          activeMetrics={allFilters.activeMetrics.map((metric) => metric.name)}
          onAppliedFilters={onAppliedFilters}
          onMetricsFilter={onMetricsFilterChange}
          onCreateReportOpen={() => createReportModal.setOpened(true)}
          onEditReportSaveOpen={() => editReportModal.setOpened(true)}
        />

        {!isADSCardsLoading && (
          <Box>
            <Typography type="body2" weight="bold">
              Color sorting by
            </Typography>
            <div className={styles.colorMetricsPanel}>
              <Button
                color="primary"
                variant="outlined"
                before={<Icon name="plus" />}
                onClick={() => setShowColorMetricsSelect((prev) => !prev)}
              >
                Add metrics
              </Button>
              {showColorMetricsSelect && (
                <SimpleFiltersMultiselect
                  className={styles.colorMetricsSelect}
                  withSearch
                  inputClassName={styles.colorMetricsSelectInput}
                  onSelect={handleMetricsOptions}
                  value={sortColorMetrics}
                  placeholder="Add metric"
                  onClickOutside={() => setShowColorMetricsSelect(false)}
                  {...metricsOptions}
                />
              )}
              {sortColorMetrics.map((filter) => {
                if (!filter) return null
                return (
                  <Chip
                    type="contained"
                    color="secondary"
                    endIcon={
                      <Icon
                        name="cross"
                        onClick={() => handleRemoveColorMetric(filter)}
                      />
                    }
                    className={styles.filterChip}
                    key={filter}
                  >
                    {filter}
                  </Chip>
                )
              })}
            </div>
          </Box>
        )}

        {isADSCardsLoading && <Loader />}

        {view === "table" && !isADSCardsLoading && !isLoading && (
          <Table
            className={styles.table}
            mapper={Object.values(
              mapper(allFilters.activeMetrics, metricsTrend)
            )}
            data={filteredCards}
            metricsTrend={metricsTrend}
            onRowClick={handleOpenReportDetailsModal}
          />
        )}

        <div className={styles.grid}>
          {view === "card" &&
            groupingType === "None" &&
            !isADSCardsLoading &&
            !isLoading &&
            paginationData.map((card) => (
              <ReportCard
                onOpenVideoPreview={handleOpenVideoPreview}
                data={card}
                metricsTrend={metricsTrend}
                metrics={allFilters.activeMetrics}
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  handleOpenReportDetailsModal(card)
                }}
                onOpenKPIChart={handleOpenKPItrendModal}
                key={card.Id}
              />
            ))}
        </div>

        <div className={styles.gridGroups}>
          {!isLoading &&
          !isADSCardsLoading &&
          filteredGroups &&
          groupingType !== "None" &&
          view !== "table" &&
          filteredGroups[groupingType]
            ? filteredGroups[groupingType].map(
                (group: ADSAnalyticsGroupTypeEntity) => {
                  const cards =
                    filteredCards.filter((card) =>
                      group.Ads.includes(card.Id)
                    ) || []

                  return cards.length ? (
                    <GroupCard
                      adsCard={cards[0]}
                      groupAds={cards}
                      groupingType={groupingType}
                      data={group}
                      metricsTrend={metricsTrend}
                      metrics={allFilters.activeMetrics}
                      total={calculateTotalCardsWithinGroup(group)}
                      onClick={() => handleGroupClick(group)}
                      onOpenVideoPreview={handleOpenVideoPreview}
                    />
                  ) : null
                }
              )
            : null}
        </div>
      </Stack>

      <CreateReportModal
        isOpen={createReportModal.isOpened}
        onClose={() => createReportModal.setOpened(false)}
        onCreateReport={onCreateReport}
        isLoading={isReportLoading}
      />

      <EditReportModal
        reportName={reportData?.settings.name as string}
        isOpen={editReportModal.isOpened}
        onClose={() => editReportModal.setOpened(false)}
        onEditReport={onEditReport}
      />

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
        reportDetails={reportDetails as AnalyticsCard}
        metricsTrend={metricsTrend}
        onPreviousClick={handlePreviousDetailsCard}
        onNextClick={handleNextDetailsCard}
      />

      <KPITrendModal
        activePeriodicity={activePeriodicity}
        onActivePeriodicity={handleActivePeriodicity}
        report={report}
        data={KPITrendsData}
        open={KPImodal.isOpened}
        isKPILoading={isKPILoading}
        onClose={() => KPImodal.setOpened(false)}
      />

      {videoPreviewModal.isOpened && reportDetails ? (
        <VideoPreviewModal
          positionLeft={isGroupsSidebarOpen}
          data={reportDetails}
          onDetailsOpen={() => {
            modal.setOpened(true)
            videoPreviewModal.setOpened(false)
          }}
          onClose={() => videoPreviewModal.setOpened(false)}
        />
      ) : null}

      <GroupsSidebar
        period={period}
        metricsTrend={metricsTrend}
        activeGroupAds={activeGroupAds}
        activeGroup={activeGroup}
        activeMetrics={allFilters.activeMetrics}
        adsCardsData={adsCardsData}
        isOpen={isGroupsSidebarOpen}
        onClose={() => setIsGroupsSidebarOpen(false)}
        onOpenKPItrendModal={handleOpenKPItrendModal}
        onOpenDetailsModal={(card) => {
          handleOpenReportDetailsModal(card)
        }}
        onVideoPreviewOpen={(card) => {
          handleOpenVideoPreview(card)
        }}
      />
    </div>
  )
})

export default GeneralPage
