import React, { FC } from "react"
import clsx from "clsx"
import { metricNamesListV1 } from "@framework/types/metrics"
import Typography from "@components/ui/Typography/Typography"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { PeriodMetrics } from "./utils"

import styles from "./ChannelPerfomanceDetails.module.scss"

interface StaticColumnProps {
  data?: PeriodMetrics
}

const StaticColumn: FC<StaticColumnProps> = ({ data }) => (
  <div className={styles.staticColumnContainer}>
    {metricNamesListV1.map((metricName) => {
      const metric = getMetricDescriptor(metricName)
      return (
        <Typography
          type="body1"
          className={clsx(styles.metricItem, styles.staticMetrics)}
        >
          {metric.formatter(data?.[metricName].Value || 0)}
        </Typography>
      )
    })}
  </div>
)

export default StaticColumn
