import React, { FC } from "react"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { Button } from "@components/ui/Button"
import BackButton from "@framework/prototypes/BackButton"

import styles from "./CheckInbox.module.scss"

interface CheckInboxProps {
  onBackClick: () => void
  onResendLink: () => void
}

const CheckInbox: FC<CheckInboxProps> = ({ onBackClick, onResendLink }) => (
  <Stack direction="column" gutter="24" align="center" className={styles.root}>
    <BackButton onClick={onBackClick} className={styles.backButton} />
    <Typography type="h1" weight="bold">
      Check your inbox
    </Typography>
    <Stack gutter="16" align="center">
      <Typography>
        A confirmation link has been sent to user@example.com. Please check your
        inbox to verify your account.
      </Typography>
      <Stack gutter="0" direction="row" justify="center" align="center">
        <Typography>Did not receive the link?</Typography>
        <Button variant="ghost" color="primary" onClick={onResendLink}>
          Resend it
        </Button>
      </Stack>
    </Stack>
  </Stack>
)

export default CheckInbox
