import React, { FC, useMemo } from "react"
import { ChartOptions } from "chart.js"
import { Bar } from "react-chartjs-2"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import LegendList from "@components/ui/Charts/Legends/LegendList"
import { DataSetType } from "@components/ui/Charts/BarChart/BarChart"

import styles from "./GoogleHostoricalMonthlyAdSpend.module.scss"

const LEGEND_LIST = {
  labels: [{ label: "Previus" }, { label: "Current" }],
  colors: ["#D1E7EC", "#74B8C5"],
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

interface GoogleHostoricalMonthlyAdSpendProps {
  dataset: DataSetType[]
}

const GoogleHostoricalMonthlyAdSpend: FC<
  GoogleHostoricalMonthlyAdSpendProps
> = ({ dataset }) => {
  const options: ChartOptions<any> = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
        },
        border: {
          display: false,
        },
      },
      y: {
        display: true,
        grid: {
          display: true,
          drawTicks: false,
        },
        ticks: {
          count: 7,
          display: false,
        },
        border: {
          display: false,
        },
        position: "right",
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    borderSkipped: false,
    borderRadius: 100,
    barPercentage: 0.9,
    categoryPercentage: 0.8,
  }

  const chartData = useMemo(
    () => ({
      labels: months,
      datasets: [
        {
          label: LEGEND_LIST.labels[0].label,
          data: dataset[0]?.data || [],
          backgroundColor: LEGEND_LIST.colors[0],
        },
        {
          label: LEGEND_LIST.labels[1].label,
          data: dataset[1]?.data || [],
          backgroundColor: LEGEND_LIST.colors[1],
        },
      ],
    }),
    [dataset]
  )

  return (
    <div className={styles.root}>
      <Stack direction="column" gutter="24">
        <div>
          <Typography
            type="h2"
            color="dark"
            weight="bold"
            className={styles.headerText}
          >
            Historical Month Ad Spend
          </Typography>
          <Typography
            type="subhead1"
            color="gray"
            upperCase
            weight="semibold"
            className={styles.headerText}
          >
            (VS previous year)
          </Typography>
        </div>

        <LegendList
          items={LEGEND_LIST.labels}
          colors={LEGEND_LIST.colors}
          circle
        />
        <div className={styles.bar}>
          <Bar data={chartData} options={options} />
        </div>
      </Stack>
    </div>
  )
}
export default GoogleHostoricalMonthlyAdSpend
