import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ScrollSync } from "react-scroll-sync"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { FixedSizeList as List } from "react-window"
import type { ListChildComponentProps } from "react-window"
import { useStore } from "@store/index"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import {
  MetricNameTypeV1,
  MetricsTrendType,
  MetricType,
} from "@framework/types/metrics"
import { calculateTotalMetrics } from "@framework/constants/metrics"
import MetricsTableHeader from "./MetricsTableHeader"
import MetricsTableRow from "./MetricsTableRow"

import styles from "./MetricsTable.module.scss"
import { ChannelAnalyticsCard, ColumnMapper } from "./Table/types"
import Footer from "./Table/Footer"

const SINGLE_ROW_HEIGHT = 48

const STATIC_BEFORE = 1

export interface SortState {
  column: string
  direction: "asc" | "desc"
}

interface TableProps<T extends object> {
  className?: string
  mapper: ColumnMapper<T>[]
  data: ChannelAnalyticsCard[]
  metricsTrend?: MetricsTrendType
  lastUpdate?: number
  onRowClick?: (item: AnalyticsCard) => void
}

const MetricsTable = observer(
  <T extends object>({
    className,
    mapper,
    data,
    lastUpdate,
    metricsTrend,
    onRowClick,
  }: TableProps<T>) => {
    const {
      analyticsFiltersStore: { allFilters },
    } = useStore()
    const infiniteLoaderRef = React.useRef<InfiniteLoader>(null)
    const hasMountedRef = React.useRef(false)
    const [sortState, setSortState] = useState<SortState>({
      column: "",
      direction: "asc",
    })
    const [sortedData, setSortedData] = useState<ChannelAnalyticsCard[]>([])

    const [total, setTotal] = useState<Record<MetricNameTypeV1, MetricType>>({
      AOV: { Value: 0 },
      Clicks: { Value: 0 },
      Impressions: { Value: 0 },
      Conversions: { Value: 0 },
      CostConv: { Value: 0 },
      ConversionRate: { Value: 0 },
      Revenue: { Value: 0 },
      Ctr: { Value: 0 },
      AverageCpc: { Value: 0 },
      Cpm: { Value: 0 },
      Roas: { Value: 0 },
      Cost: { Value: 0 },
      Thumbstop: { Value: 0 },
      Holdplay: { Value: 0 },
    })

    const isRowLoaded = (idx: number) => !!sortedData?.[idx]

    const renderRow = ({ index, style }: ListChildComponentProps) => {
      const item = sortedData?.[index]

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div style={style} className={styles.row} onClick={() => {}}>
          <MetricsTableRow
            metricsTrend={metricsTrend}
            data={item}
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
          />
        </div>
      )
    }

    const item = sortedData?.[0]

    const handleSort = (column: string) => {
      setSortState((prevState) => {
        const isSameColumn = prevState.column === column
        const nextDirection =
          isSameColumn && prevState.direction === "asc" ? "desc" : "asc"
        return { column, direction: nextDirection }
      })
    }

    useEffect(() => {
      if (!data) return
      const res: Record<MetricNameTypeV1, MetricType> = calculateTotalMetrics(
        data as AnalyticsCard[]
      )
      setTotal(res)
    }, [data, allFilters])

    useEffect(() => {
      if (hasMountedRef.current && infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true)
      }
      hasMountedRef.current = true
    }, [lastUpdate])

    useEffect(() => {
      setSortedData(data)
    }, [sortState, data])

    return (
      <ScrollSync>
        <div className={clsx(styles.root, className)}>
          <MetricsTableHeader
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
            sortState={sortState}
            onSort={handleSort}
          />
          <Footer
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
            data={total}
          />
          <div className={clsx(styles.body)}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isRowLoaded}
              itemCount={sortedData.length}
              loadMoreItems={() => {}}
              threshold={40}
              minimumBatchSize={40}
            >
              {({ onItemsRendered, ref }) => (
                <div style={{ minHeight: "98px" }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        className={styles.list}
                        height={height}
                        width={width}
                        itemCount={sortedData.length}
                        itemSize={SINGLE_ROW_HEIGHT}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                      >
                        {renderRow}
                      </List>
                    )}
                  </AutoSizer>
                </div>
              )}
            </InfiniteLoader>
          </div>
        </div>
      </ScrollSync>
    )
  }
)

export default MetricsTable
