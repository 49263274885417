import { PeriodMetricsChange, TableData } from "./utils"

export const DATA: TableData["data"] = [
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
  {
    Period: "2025-03-17",
    Metrics: {
      Clicks: {
        Value: 9,
      },
      Cost: {
        Value: 8.8,
      },
      AverageCpc: {
        Value: 0.977777,
      },
      Impressions: {
        Value: 16,
      },
      Ctr: {
        Value: 0.5625,
      },
      Cpm: {
        Value: 550,
      },
      AOV: {
        Value: 0,
      },
      Roas: {
        Value: 0,
      },

      ConversionRate: {
        Value: 0,
      },
      CostConv: {
        Value: 0,
      },
      Revenue: {
        Value: 0,
      },
      Conversions: {
        Value: 0,
      },
      Holdplay: {
        Value: 0,
      },
      Thumbstop: {
        Value: 0,
      },
    },
  },
]

export const mockSummaryData: PeriodMetricsChange = {
  Clicks: {
    Value: 9,
    Changes: -23,
  },
  Cost: {
    Value: 8.8,
    Changes: 0.5,
  },
  AverageCpc: {
    Value: 0.977777,
    Changes: 0.5,
  },
  Impressions: {
    Value: 16,
    Changes: 0.5,
  },
  Ctr: {
    Value: 0.5625,
    Changes: 0.5,
  },
  Cpm: {
    Value: 550,
    Changes: 0.5,
  },
  AOV: {
    Value: 0,
    Changes: 0.5,
  },
  Roas: {
    Value: 0,
    Changes: 0.5,
  },

  ConversionRate: {
    Value: 0,
    Changes: 0.5,
  },
  CostConv: {
    Value: 0,
    Changes: 0.5,
  },
  Revenue: {
    Value: 0,
    Changes: 0.5,
  },
  Conversions: {
    Value: 0,
    Changes: 0.5,
  },
  Holdplay: {
    Value: 0,
    Changes: 0.5,
  },
  Thumbstop: {
    Value: 0,
    Changes: 0.5,
  },
}

export default {}
