import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

// import TechInfo from "./components/TechInfo/TechInfo"
import { useStore } from "@store/index"
import { useAlert } from "react-alert"
import ServiceDownInterceptor from "@framework/prototypes/ServiceDownInterceptor/ServiceDownInterceptor"
import GoogleAdSpendBudget from "@pages/Account/AccountDashboard/components/GoogleAdSpendBudget/GoogleAdSpendBudget"
import OverallKPIs from "../../Account/AccountDashboard/components/OverallKPIs/OverallKPIs"
import HistoricalKPITrends from "../../Account/AccountDashboard/components/HistoricalKPITrends/HistoricalKPITrends"
import CampaignPerformance from "../../Account/AccountDashboard/components/CampaignPerformance/CampaignPerformance"
import Filter from "../../Account/AccountDashboard/components/Filter/Filter"

import styles from "./Dashboard.module.scss"

type DashboardProps = {
  className?: string
}

const Dashboard: React.FC<DashboardProps> = observer(({ className }) => {
  const {
    accountDashboardStore,
    accountStore: { account },
  } = useStore()

  const alert = useAlert()

  useEffect(() => {
    if (!account?.isConnected) {
      alert.removeAll()
      alert.show(
        "Google Ads account is not connected. Please, connect the account to see the data"
      )
    }
  }, [])

  const { setDateRange } = accountDashboardStore

  return (
    <div className={clsx(styles.root, className)}>
      <ServiceDownInterceptor />
      <div className={styles.wrapper}>
        {/* <TechInfo /> */}
        <Filter onSubmit={setDateRange} isDisabled={!account?.isConnected} />
      </div>
      <OverallKPIs />
      <HistoricalKPITrends />
      <GoogleAdSpendBudget />
      <CampaignPerformance />
    </div>
  )
})

export default Dashboard
