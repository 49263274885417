import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import * as yup from "yup"
import { FormikProvider, useFormik } from "formik"

import PublicLayout from "@root/layouts/public/PublicLayout"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import { emailValidator } from "@components/utils/validators"
import { useStore } from "@store/index"
import useValidShopifyCredentials from "@framework/hooks/useValidShopifyCredentials"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import FormTextField from "@framework/prototypes/FormTextField"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import Stack from "@components/ui/Stack/Stack"
import usePasswordFiledToggle from "@components/ui/TextField/usePasswordFiledToggle"
import config from "@root/config"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"
import Divider from "@components/ui/Divider/Divider"

import styles from "./Login.module.scss"
import SSOButton from "../../components/ui/Button/SSOButton/SSOButton"

export const validationSchema = yup.object({
  email: emailValidator.clone().required("Can not be empty").default(""),
  password: yup.string().required("Can not be empty").default(""),
  rememberMe: yup.boolean().default(true),
})

type LoginFormData = yup.InferType<typeof validationSchema>

const initialValues: LoginFormData = validationSchema.getDefault()

const Login: React.FC = observer(() => {
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()
  const {
    authStore: { isAuthorized, user, isLoading, login, loginWithShopify },
  } = useStore()

  const form = useValidShopifyCredentials()

  const handleSubmit = async (form: LoginFormData) => {
    const error = await login(form.email, form.password)
    if (error) {
      alert.error(<AlertMessage title="Login failed" description={error} />)
    }
  }

  const handleRestorePassword = () => history.push("/forgot-password")

  const formik = useFormik<LoginFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  if (!config.HIDE_SHOPIFY) {
    useEffect(() => {
      if (!isAuthorized) return

      if (!form) {
        history.push("/")
        return
      }
      if (user?.Email) {
        loginWithShopify(user.Email, form).then(({ propertyId, error }) => {
          if (error) {
            alert.error(
              <AlertMessage
                title="Failed to login Shopify"
                description={error}
              />
            )
          }
          if (propertyId != null) {
            history.push(`/shopping/${propertyId}/dashboard/welcome`)
          }
        })
      }
    }, [isAuthorized, form, user])
  } else {
    useEffect(() => {
      if (isAuthorized) history.push("/")
    }, [isAuthorized])
  }

  const handleSignUpRedirect = () => {
    history.replace(`/signup${location.search}`)
  }

  const passToggleProps = usePasswordFiledToggle()

  return (
    <PublicLayout color="default">
      <FormikProvider value={formik}>
        <form className={styles.root} onSubmit={formik.handleSubmit}>
          <Stack align="center" gutter="8">
            <Typography type="h1" color="dark" weight="bold">
              Welcome back!
            </Typography>
            <Typography
              type="body1"
              color="dark"
              className={styles.signUpContainer}
            >
              Do not have an account?
              <Button
                onClick={handleSignUpRedirect}
                color="primary"
                variant="ghost"
                className={styles.button}
              >
                Sign Up
              </Button>
            </Typography>
          </Stack>
          <Stack
            gutter="4"
            direction="column"
            className={styles.ssoAuthContainer}
          >
            <SSOButton variant="Google" />
            <SSOButton variant="Apple" />
            <SSOButton variant="Facebook" />
          </Stack>

          <Stack direction="row" gutter="8">
            <Divider />
            <Typography type="body2">or</Typography>
            <Divider />
          </Stack>

          <Stack
            direction="column"
            align="stretch"
            gutter="8"
            className={styles.authContainer}
          >
            <FormTextField label="Email" name="email" placeholder="Email" />

            <FormTextField
              label="Password"
              name="password"
              placeholder="Password"
              {...passToggleProps}
            />
            <Stack direction="row" justify="space-between">
              <InlineLabel text="Remember me" color="black60Color">
                <CheckBox
                  name="rememberMe"
                  checked={formik.values.rememberMe}
                  onChange={formik.handleChange}
                />
              </InlineLabel>
              <Button
                variant="ghost"
                color="primary"
                onClick={handleRestorePassword}
              >
                Forgot Password?
              </Button>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoading}
              type="submit"
              className={styles.submitButton}
            >
              Sign In
            </Button>
          </Stack>
          <Typography
            type="body1"
            color="black70Color"
            className={styles.termsText}
          >
            By signing in using any of the options above, you agree to the
            <span> Terms of Use</span> & <span>Privacy Policy</span>
          </Typography>
        </form>
      </FormikProvider>
    </PublicLayout>
  )
})

export default Login
