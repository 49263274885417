import React, { FC, ReactNode } from "react"
import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import Typography from "@components/ui/Typography/Typography"

import styles from "./ChannelPerfomanceDetails.module.scss"

interface AdDropdownProps {
  title: string
  isOpen: boolean
  icon: ReactNode
  onToggle: () => void
}

const AdDropdown: FC<AdDropdownProps> = ({ isOpen, icon, title, onToggle }) => (
  <Stack
    direction="row"
    align="center"
    className={styles.tagContainer}
    style={{
      borderBottomLeftRadius: isOpen ? "none" : "0.5rem",
      borderBottomRightRadius: isOpen ? "none" : "0.5rem",
    }}
    onClick={onToggle}
  >
    <Icon name="arrow-down" rotateAngle={isOpen ? 180 : 0} />
    {icon}
    <Typography type="h4">{title}</Typography>
  </Stack>
)

export default AdDropdown
