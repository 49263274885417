import React, { FC, useState } from "react"
import { ScrollSync } from "react-scroll-sync"
import GoogleAdsIcon from "@root/assets/svgIcons/GoogleAdsIcon"
import Stack from "@components/ui/Stack/Stack"
import FacebookIcon from "@root/assets/svgIcons/FacebookIcon"
import { OpenMetricsTable, TableData } from "./utils"
import AdsDropdown from "./AdsDropdown"
import MetricsColumn from "./MetricsColumn"
import MetricsStatisticGrid from "./MetricsStatisticGrid"
import TableHeader from "./TableHeader"
import StaticColumn from "./StaticColumn"
import SummaryStaticColumn from "./SummaryStaticColumn"
import { mockSummaryData } from "./mockData"

import styles from "./ChannelPerfomanceDetails.module.scss"

interface TableProps {
  data?: TableData["data"]
}

const Table: FC<TableProps> = ({ data }) => {
  const [isOpenMetricsTable, setIsOpenMetricsTable] =
    useState<OpenMetricsTable>({ isOpenFacebook: false, isOpenGoogle: false })

  const handleToogleGoogleAds = () => {
    setIsOpenMetricsTable((prev) => ({
      ...prev,
      isOpenGoogle: !prev.isOpenGoogle,
    }))
  }

  const handleToogleFacebookAds = () => {
    setIsOpenMetricsTable((prev) => ({
      ...prev,
      isOpenFacebook: !prev.isOpenFacebook,
    }))
  }

  return (
    <ScrollSync>
      <Stack direction="column" gutter="8" className={styles.root}>
        {(isOpenMetricsTable.isOpenGoogle ||
          isOpenMetricsTable.isOpenFacebook) && (
          <TableHeader
            data={data}
            staticDate="2025-03-21"
            summaryStaticDate="2025-03-22"
          />
        )}
        <div className={styles.channelItem}>
          <AdsDropdown
            isOpen={isOpenMetricsTable.isOpenGoogle}
            icon={<GoogleAdsIcon />}
            title="Google Ads"
            onToggle={handleToogleGoogleAds}
          />
          {isOpenMetricsTable.isOpenGoogle && (
            <div className={styles.mainGrid}>
              <MetricsColumn />
              <MetricsStatisticGrid data={data} />
              <StaticColumn data={data?.[0].Metrics} />
              <SummaryStaticColumn data={mockSummaryData} />
            </div>
          )}
        </div>
        <div className={styles.channelItem}>
          <AdsDropdown
            isOpen={isOpenMetricsTable.isOpenFacebook}
            icon={<FacebookIcon />}
            title="Facebook Ads"
            onToggle={handleToogleFacebookAds}
          />
          {isOpenMetricsTable.isOpenFacebook && (
            <div className={styles.mainGrid}>
              <MetricsColumn />
              <MetricsStatisticGrid data={data} />
              <StaticColumn data={data?.[0].Metrics} />
              <SummaryStaticColumn data={mockSummaryData} />
            </div>
          )}
        </div>
      </Stack>
    </ScrollSync>
  )
}
export default Table
