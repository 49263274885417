import React from "react"

import Typography from "@components/ui/Typography/Typography"
import GoogleAdsIcon from "@root/assets/svgIcons/GoogleAdsIcon"
import FacebookIcon from "@root/assets/svgIcons/FacebookIcon"

import { ViewCellProps } from "../types"

import styles from "./TableCell.module.scss"

const IconsMap: Record<string, JSX.Element> = {
  "Google Ads": <GoogleAdsIcon />,
  Facebook: <FacebookIcon />,
}

export const LabelCell: React.FC<ViewCellProps> = React.memo(
  ({ name, data }) => {
    const value = data[name]
    return (
      <div className={styles.labelCell}>
        {IconsMap[value as string]} {/* fix types */}
        <Typography type="h4" weight="semibold" color="black100Color">
          {value}
        </Typography>
      </div>
    )
  }
)

export default LabelCell
