/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { useHistory } from "react-router-dom"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import DoughnutChart from "@components/ui/Charts/DoughutChartV2/DoughutChart"
import { useStore } from "@store/index"
import { DEF_YYYY_MM_DD_DATE_FORMAT } from "@framework/constants/moment-format"

import styles from "./MonthAdSpendChart.module.scss"

import StatisticCard from "./StatisticCard"

const AdsLabelsMock = ["Google Ads", "Facebook", "TikTok"]

const mockData = [13, 67, 80]

const COLORS_FOR_CHART = [
  "#D1E7EC",
  "#4196C6",
  "#58B7C9",
  "#A8DAC8",
  "#EAC974",
  "#005B70",
  "#FBE0DE",
]

type MonthAdSpendChartProps = {
  className?: string
}

const MonthAdSpendChart: React.FC<MonthAdSpendChartProps> = observer(
  ({ className }) => {
    const history = useHistory()
    const {
      accountDashboardStore: { monthlyPerformanceStore },
      accountStore,
    } = useStore()
    const { currYearPerf, checkbookAmount } = monthlyPerformanceStore
    const { account } = accountStore

    const { dayOfMonthLeft, monthName, daysInMonth } = useMemo(() => {
      const currentDate = Date.now()
      const dayOfMonthLeft = Number.parseInt(
        moment(currentDate).format("DD"),
        10
      )
      const monthName = moment(currentDate).format("MMMM YYYY")
      const daysInMonth = moment(currentDate).daysInMonth()
      return { dayOfMonthLeft, monthName, daysInMonth }
    }, [])

    const latestMonthPerformance = useMemo(() => {
      if (currYearPerf) {
        const currentMonth = moment
          .utc()
          .startOf("month")
          .format(DEF_YYYY_MM_DD_DATE_FORMAT)
        const idx = currYearPerf.findIndex(
          ({ Period }) => Period === currentMonth
        )
        if (idx < 0) return null
        return currYearPerf[idx]
      }
      return null
    }, [currYearPerf])

    const data = useMemo(() => {
      if (account && latestMonthPerformance) {
        const totalBudget =
          checkbookAmount ?? Number.parseFloat(account.yearlyAdBudget) / 12
        const daysLeftPercent = dayOfMonthLeft / daysInMonth
        const currentSpend = latestMonthPerformance.Metrics.Cost?.Value ?? 0

        const spendPerDay = currentSpend / daysLeftPercent
        const currentPercent = (spendPerDay / totalBudget) * 100
        return {
          currentSpend,
          totalBudget,
          currentPercent,
          daysLeftPercent,
        }
      }
      return null
    }, [
      account,
      latestMonthPerformance,
      dayOfMonthLeft,
      daysInMonth,
      checkbookAmount,
    ])

    // Calculate percentages from the raw data
    const percentages = useMemo(() => {
      const sum = mockData.reduce((acc, val) => acc + val, 0)
      return mockData.map((value) => Math.round((value / sum) * 100))
    }, [data])

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div className={clsx(styles.root, className)}>
        <Typography type="h1" color="dark" weight="bold">
          This Month's Ad Spend
        </Typography>
        <Typography type="subhead1" color="gray" weight="semibold">
          ({monthName})
        </Typography>
        <Stack
          direction="row"
          gutter="16"
          className={styles.cardsStatisticsWrapper}
        >
          <StatisticCard
            isPriceBold
            label="Month to Date Spend"
            price="$1000"
          />
          <StatisticCard label="Month to Date Spend" price="$1000" />
        </Stack>
        <Stack
          direction="row"
          gutter="32"
          align="center"
          className={styles.doughnutChartContainer}
        >
          <div style={{ width: "210px", height: "210px" }}>
            <DoughnutChart
              data={mockData}
              labels={["Google Ads", "Facebook", "TikTok"]}
              colors={COLORS_FOR_CHART}
            />
          </div>
          <Stack>
            {AdsLabelsMock.map((el, index) => (
              <Stack direction="row" gutter="8">
                <div
                  className={styles.colorMark}
                  style={{ backgroundColor: COLORS_FOR_CHART[index] }}
                />
                <Typography type="body2" color="black80Color">
                  {el}{" "}
                  <span className={styles.percentages}>
                    {percentages[index]}%
                  </span>
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </div>
    )
  }
)

export default MonthAdSpendChart
