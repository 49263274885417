import React, { FC } from "react"

interface AppleIconProps {
  width?: number
  height?: number
}

const AppleIcon: FC<AppleIconProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0547 5.53846C14.138 5.53846 15.4959 4.8061 16.3045 3.82962C17.0369 2.94469 17.5709 1.70884 17.5709 0.472982C17.5709 0.30515 17.5556 0.137317 17.5251 0C16.3198 0.0457724 14.8703 0.808646 14.0006 1.8309C13.3141 2.60903 12.6885 3.82963 12.6885 5.08074C12.6885 5.26383 12.719 5.44692 12.7343 5.50795C12.8106 5.5232 12.9326 5.53846 13.0547 5.53846ZM9.2403 24C10.7203 24 11.3764 23.0083 13.2225 23.0083C15.0992 23.0083 15.5111 23.9695 17.1589 23.9695C18.7762 23.9695 19.8595 22.4743 20.8818 21.0095C22.0261 19.3312 22.499 17.6834 22.5296 17.6071C22.4228 17.5766 19.3255 16.3102 19.3255 12.7552C19.3255 9.67324 21.7667 8.28481 21.904 8.178C20.2867 5.85887 17.8303 5.79784 17.1589 5.79784C15.3433 5.79784 13.8633 6.89638 12.9326 6.89638C11.9256 6.89638 10.5982 5.85887 9.0267 5.85887C6.03624 5.85887 3 8.33058 3 12.9994C3 15.8983 4.12905 18.965 5.51748 20.9485C6.70756 22.6268 7.74507 24 9.2403 24Z"
      fill="black"
    />
  </svg>
)

export default AppleIcon
