import React, { FC } from "react"
import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import InlineLabel from "@components/ui/CheckBox/Label/Label"
import CheckBox from "@components/ui/CheckBox/CheckBox/CheckBox"

import styles from "./MetricsSideBar.module.scss"

interface MetricItemProps {
  label: string
  isCheked: boolean
  onToogle: () => void
}

const MetricItem: FC<MetricItemProps> = ({ label, isCheked, onToogle }) => (
  <Stack direction="row" align="center" gutter="12">
    <Icon name="menu" className={styles.metricIcon} />
    <InlineLabel type="h4" text={label}>
      <CheckBox name={label} checked={isCheked} onChange={onToogle} />
    </InlineLabel>
  </Stack>
)

export default MetricItem
