import React, { FC } from "react"
import { Chart, ArcElement, Tooltip, Legend, ChartOptions } from "chart.js"
import { Bar } from "react-chartjs-2"

import { monthListMock } from "@framework/constants/common"

Chart.register(ArcElement, Tooltip, Legend)

interface DoughutChartProps {
  data: number[]
  colors: string[]
  labels: string[]
  className?: string
}

const StackBarChart: FC<DoughutChartProps> = ({
  data,
  labels,
  className,
  colors,
}) => {
  const chartData = {
    labels: [...monthListMock],
    datasets: [
      {
        label: "Google Ads",
        data: [1000, 256, 893, 572, 25, 987, 56, 14, 0, 0, 0, 0],
        backgroundColor: "#E0E0E0",
        borderWidth: 1,
        barPercentage: 0.6,
      },
      {
        label: "Facebook",
        data: [1000, 256, 893, 572, 25, 987, 56, 14, 0, 0, 0, 0],
        backgroundColor: "#4BACC6",
        borderWidth: 1,
        barPercentage: 0.6,
      },
      {
        label: "TikTok",
        data: [3000, 1000, 244, 572, 25, 987, 56, 14, 0, 0, 0, 0],
        backgroundColor: "#3A75B0",
        borderWidth: 1,
        barPercentage: 0.6,
      },
    ],
  }
  const options: ChartOptions<any> = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          callback: (value: any) => `$${value}`,
          stepSize: 1000,
        },
        grid: {
          color: "#E5E7EB",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  }
  return <Bar redraw data={chartData} options={options} className={className} />
}

export default StackBarChart
