import { AxiosResponse } from "axios"
import {
  TimeSegmentedPerformanceReport,
  PerformanceReport,
} from "@framework/types/metrics"
import config from "@root/config"
import { ClientsPerformance, Client, ClientFile } from "@framework/types/client"
import { AUTH_TOKEN_KEY } from "@framework/constants/auth"
import { ClientInfoType } from "@framework/types/clientProfile"
import { CheckbookReport } from "@framework/types/clientCheckbook"
import {
  ComparedReportPeriodFilter,
  PerformancePeriodReportFilter,
  PeriodFilter,
} from "@framework/types/dashboard"
import {
  ExtendedReportFilterRequest,
  ReportFilterRequest,
} from "@store/reports/types"
import { Platform, CampaignGroup } from "@framework/constants/report"
import HttpService from "./http.service"

export interface GetAgencyClientsResponse {
  data: any
}

export interface GetClientsResponse {
  data: Client[]
}

export interface GetClientByIdResponse {
  data: Client
}

export interface PerformancePeriodReportResponse {
  data: TimeSegmentedPerformanceReport[]
}

export interface PerformanceReportResponse {
  data: {
    Metrics: PerformanceReport
    AccountsPerformance: ClientsPerformance
  }
}

export interface GetCheckbookAmountResponse {
  data: number
}

export interface GetClientCheckbookResponse {
  data: {
    numberOfAccounts: number
    summary: CheckbookReport
    allCheckbooks: CheckbookReport[]
  }
}

export interface GetFileListResponse {
  data: ClientFile[]
}

export interface GetFileProgressResponse {
  data: any
}

export type CampaignReportEntity = {
  Id: number
  Name: string
  Status: string
} & PerformanceReport

export type MetaAdSetEntity = {
  id: number
  name: string
  status: string
} & PerformanceReport

export type MetaCampaignReportEntity = {
  id: number
  name: string
  status: string
  Adsets?: MetaAdSetEntity[] | null
} & PerformanceReport

export type ChannelReportEntity = {
  CampaignsReport: CampaignReportEntity[] | null
  ChannelId: CampaignGroup
  Summary: PerformanceReport
}

export type AccountReportEntity = {
  AccountId: number
  // googleAds platform
  GoogleAdsReport: {
    ChannelsReport: ChannelReportEntity[] | null
    Summary: PerformanceReport
  } | null
  // meta platform
  MetaAdsReport: {
    MetaCampaignsReport: MetaCampaignReportEntity[] | null
    Summary: PerformanceReport
  } | null
  MonthlyReport: PeriodReportEntity[] | null
  WeeklyReport: PeriodReportEntity[] | null
  Summary: PerformanceReport
}

export type PlatformReportEntity = {
  PlatformId: Platform
  Summary: PerformanceReport
}

export type PeriodReportEntity = {
  Period: string // YYYY-MM-DD
  PlatformReports: PlatformReportEntity[] | null
  Summary: PerformanceReport
}

export interface GetExpandedReportResponse {
  data: {
    Accounts: AccountReportEntity[] | null
    PlatformsSummary: PlatformReportEntity[] | null
  } | null
}

export interface GetReportKPIsResponse {
  data: PerformanceReport
}

export interface GetReportHistoricalKPIsResponse {
  data: TimeSegmentedPerformanceReport[]
}

class ClientsAPI extends HttpService {
  fetchAgencyClients = (
    id: number
  ): Promise<AxiosResponse<GetAgencyClientsResponse>> =>
    this.get(`agency/${id}/clients`)

  fetchClients = (): Promise<AxiosResponse<GetClientsResponse>> =>
    this.get(`clients/list`, { statsInfo: true })

  createAgencyClient = (
    body: Partial<Client>
  ): Promise<AxiosResponse<GetClientByIdResponse>> => this.post("client", body)

  fetchClientById = (
    clientId: number
  ): Promise<AxiosResponse<GetClientByIdResponse>> =>
    this.get(`client/${clientId}`, { statsInfo: true })

  getPerformancePeriodReport = (
    clientId: number,
    filter: PerformancePeriodReportFilter
  ): Promise<AxiosResponse<PerformancePeriodReportResponse>> =>
    this.get(`client/${clientId}/performance-period-report`, filter)

  getPerformance = (
    clientId: number,
    filter: { from: string; to: string; compare?: boolean }
  ): Promise<AxiosResponse<PerformanceReportResponse>> =>
    this.get(`client/${clientId}/performance-report`, filter)

  getExpandedReport = (
    filter: ComparedReportPeriodFilter,
    request: ExtendedReportFilterRequest
  ): Promise<AxiosResponse<GetExpandedReportResponse>> =>
    this.post(`user/reports/expanded`, request, {}, true, filter)

  getReportKPIs = (
    filter: ComparedReportPeriodFilter,
    request: ReportFilterRequest
  ): Promise<AxiosResponse<GetReportKPIsResponse>> =>
    this.post(`user/reports/kpis`, request, {}, true, filter)

  getReportHistoricalKPIs = (
    filter: PerformancePeriodReportFilter,
    request: ReportFilterRequest
  ): Promise<AxiosResponse<GetReportHistoricalKPIsResponse>> =>
    this.post(`user/reports/historical-kpis`, request, {}, true, filter)

  getReportDownloadLink = (
    { from, to }: PeriodFilter,
    request: ReportFilterRequest
  ): string => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    return this.getApiUrl(
      `user/reports/expanded/download?from=${from}&to=${to}&token=${token}&args=${JSON.stringify(
        request
      )}`
    )
  }

  getAccountCheckbookAmount = (
    clientId: number
  ): Promise<AxiosResponse<GetCheckbookAmountResponse>> =>
    this.get(`client/${clientId}/planned-spend`)

  getClientCheckbook = (
    clientId: number
  ): Promise<AxiosResponse<GetClientCheckbookResponse>> =>
    this.get(`client/${clientId}/checkbook`)

  updateClient = (
    clientId: number,
    form: ClientInfoType
  ): Promise<AxiosResponse<GetFileListResponse>> =>
    this.put(`client/${clientId}`, form)

  getClientFiles = (
    clientId: number
  ): Promise<AxiosResponse<GetFileListResponse>> =>
    this.get(`client/${clientId}/files/list`)

  getFileLink = (clientId: number, fileId: number): string => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    return this.getApiUrl(`client/${clientId}/file/${fileId}?token=${token}`)
  }

  uploadFiles = (
    clientId: number,
    form: FormData
  ): Promise<AxiosResponse<any>> => this.put(`client/${clientId}/files`, form)

  removeFiles = (
    clientId: number,
    fileId: number
  ): Promise<AxiosResponse<any>> =>
    this.delete(`client/${clientId}/file/${fileId}`)

  onboardUser = (
    body: Partial<Client>
  ): Promise<AxiosResponse<GetClientByIdResponse>> =>
    this.post("user/onboard", body)

  linkAccount = (
    clientId: number,
    token: string,
    account: string,
    accountName: string
  ): Promise<AxiosResponse<any>> =>
    this.put(
      `client/${clientId}/link-account`,
      {},
      {
        token,
        account,
        accountName,
      }
    )
}

export default new ClientsAPI({
  API_URL: config.API_ROOT,
})
