import React, { FC } from "react"

import Typography from "@components/ui/Typography/Typography"
import styles from "./MonthAdSpendChart.module.scss"

interface StatisticCardProps {
  label: string
  price: number | string
  isPriceBold?: boolean
}

const StatisticCard: FC<StatisticCardProps> = ({
  label,
  price,
  isPriceBold,
}) => (
  <div className={styles.statisticCard}>
    <Typography type="caption2" color="black60Color" weight="semibold">
      {label}
    </Typography>
    <Typography
      type={isPriceBold ? "h1" : "h2"}
      color={isPriceBold ? "primary100Color" : "black80Color"}
      weight={isPriceBold ? "bold" : "semibold"}
    >
      {price}
    </Typography>
  </div>
)

export default StatisticCard
