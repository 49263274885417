/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Templates from "@components/ui/Templates"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import { getMetricDescriptor } from "@framework/constants/metrics"
import { MetricNameType } from "@framework/types/metrics"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import Icon from "@components/ui/Icon/Icon"
import SimpleFiltersMultiselect from "@components/ui/FiltersDropDown/SimpleFiltersMultiselect"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import { metricOptions } from "@framework/types/creativeAnalytics"
import GoalWidgetCard from "./GoalWidgetCard"

import styles from "./MonthlyGoals.module.scss"

const ICON_INFO_DECRIPTION =
  "Monitor your KPIs, compare your current metrics with the same period last month and last year to measure progress."

const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const DEFAULT_ACTIVE_OPTIONS: MetricNameType[] = ["Roas", "Revenue", "Ctr"]

const MonthlyGoals: FC = observer(() => {
  const history = useHistory()
  const {
    accountStore,
    accountDashboardStore: { goals: store },
  } = useStore()
  const { accountId } = accountStore

  const metricsOptions = useOptionSearchList({
    list: metricsOptionsArray ?? [],
  })

  useEffect(() => {
    if (accountId && accountStore.account?.isConnected)
      store.loadGoalsMonthlyPerformance(accountId)
  }, [accountId, accountStore.account?.isConnected])

  const handleChange = () =>
    history.push(`/account/${accountId}/manage-profile`, { reason: "EDIT" })

  const goalsCount = store.settings?.length ?? 0
  const isInline = goalsCount < 3

  const [metricOptionsValues, setMetricOptionsValues] = useState<
    MetricNameType[]
  >(DEFAULT_ACTIVE_OPTIONS)

  const handleMetricsOptions = (value: MetricNameType) => {
    setMetricOptionsValues((prev) =>
      prev.includes(value)
        ? prev.filter((it) => it !== value)
        : [...prev, value]
    )
  }

  const [showMetrics, setShowMetrics] = useState<boolean>(false)

  return (
    <Box className={styles.root}>
      <Templates.Header
        left={
          <Stack direction="row" gutter="8" align="center">
            <Typography type="h2" weight="bold">
              KPI goal comparison
            </Typography>
            <OnClickTooltip
              placement="bottom"
              toolTip={
                <Typography className={styles.toolTip}>
                  {ICON_INFO_DECRIPTION}
                </Typography>
              }
            >
              <Typography color="lightGray">
                <Icon name="information-solid" />
              </Typography>
            </OnClickTooltip>
          </Stack>
        }
        right={
          goalsCount > 0 && (
            <Stack
              gutter="16"
              direction="row"
              justify="flex-end"
              align="center"
            >
              <Button
                onClick={handleChange}
                variant="ghost"
                color="primary"
                size="small"
              >
                Change Monthly Goals
              </Button>
              <div className={styles.filterButton}>
                <Button
                  color="secondary"
                  before={<Icon name="eye" />}
                  onClick={() => setShowMetrics((prev) => !prev)}
                >
                  Metrics
                </Button>
                {showMetrics && (
                  <div className={styles.metricsMenu}>
                    <SimpleFiltersMultiselect
                      className={styles.select}
                      inputClassName={styles.selectInput}
                      withSearch
                      onSelect={(option) => {
                        handleMetricsOptions(option as MetricNameType)
                      }}
                      value={Array.from(new Set([...metricOptionsValues]))}
                      placeholder="Search metrics"
                      onClickOutside={() => setShowMetrics(false)}
                      {...metricsOptions}
                    />
                  </div>
                )}
              </div>
            </Stack>
          )
        }
      />

      {store.settings != null && store.performance != null ? (
        store.settings.length ? (
          <div className={styles.goalWidgetContainer}>
            {metricOptionsValues.map((metric) => {
              const el = store.settings?.find(
                (settings) => settings.type === metric
              )
              const desc = getMetricDescriptor(metric)

              const metricData = store.performance?.[el?.type as MetricNameType]

              return (
                <GoalWidgetCard
                  goalValue={el?.targetValue || 0}
                  actualValue={metricData?.Value ?? 0}
                  delta={metricData?.Change ?? 0}
                  metric={desc}
                  inline={isInline}
                  className={styles.card}
                  key={metric}
                />
              )
            })}
          </div>
        ) : (
          <Stack>
            <Button onClick={handleChange} variant="outlined">
              Add monthly goals
            </Button>
          </Stack>
        )
      ) : store.isLoading ? (
        <Loader />
      ) : (
        <NoData />
      )}
    </Box>
  )
})

export default MonthlyGoals
