import React, { useEffect } from "react"
import clsx from "clsx"

import { useStore } from "@store/index"
import Typography from "@components/ui/Typography/Typography"
import { Channel } from "@store/account-linking/account-linking"

import styles from "./ConnectedChannels.module.scss"

export type ConnectedChannelsProps = {
  className?: string
  items?: Channel[]
  profileName?: string
}

const ConnectedChannels: React.FC<ConnectedChannelsProps> = ({
  className,
  items,
  profileName,
}) => {
  const {
    accountStore: { clientAccountList },
  } = useStore()

  return (
    <div className={clsx(styles.root, className)}>
      {items?.length ? (
        items.map(
          ({ value, iconSrc }) =>
            (value !== "googleAds" ||
              clientAccountList.find((el) => el.name === profileName)
                ?.isConnected) && (
              <img
                src={iconSrc}
                alt={value}
                key={value}
                className={styles.badge}
              />
            )
        )
      ) : (
        <Typography type="h5" color="dark">
          No Channels
        </Typography>
      )}
    </div>
  )
}

export default ConnectedChannels
