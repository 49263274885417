import React, { FC, useEffect, useState } from "react"
import Calendar from "react-calendar"
import clsx from "clsx"
import { format } from "date-fns"
import { datesToString } from "@components/ui/DatePicker/utils"
import Select from "@components/ui/DropDown"
import Typography from "@components/ui/Typography/Typography"
import { printOnlyDateRange } from "@utils/date"
import Stack from "@components/ui/Stack/Stack"
import Icon from "@components/ui/Icon/Icon"
import { LIST_PERIOD_OPTIONS, PeriodOption } from "./utils"

import styles from "./ChannelPerfomanceDatePicker.module.scss"

interface DatePickerProps {
  disabled?: boolean
  multiSelect?: boolean
  onChange?: (date: Date | Date[]) => void
  onBlur?: (e: any) => void
  defValue?: Date | Date[]
  minDate?: Date
  maxDate?: Date
  className?: string
  name?: string
}

export const CustomDatePicker: FC<DatePickerProps> = ({
  onChange,
  onBlur,
  multiSelect = false,
  defValue,
  minDate,
  maxDate,
  name,
  disabled = false,
  className,
}) => {
  const [isValid, setValid] = useState(true)
  const [date, setDate] = useState<Date | Date[]>([])
  const [period, setPeriod] = useState<PeriodOption>(LIST_PERIOD_OPTIONS.weekly)
  const [value, setValue] = useState<string>("")

  useEffect(() => {
    if (defValue) handlePick(defValue)
  }, [])

  const handlePick = (value: Date | Date[]) => {
    const dateStr = datesToString(value)
    setValue(dateStr)

    setDate(value)
    onChange?.(value)

    setValid(true)
  }
  return (
    <Stack
      direction="row"
      align="center"
      justify="center"
      className={styles.datePickerContainer}
    >
      <Select.DropDown
        target={
          <Stack direction="row" gutter="4" align="center">
            <Typography type="caption2" weight="bold" color="primary140Color">
              {period}
            </Typography>
            <Icon name="arrow-down" className={styles.iconStyles} />
          </Stack>
        }
      >
        <div className={styles.selectBoxContainer}>
          {Object.values(LIST_PERIOD_OPTIONS).map((item) => (
            <Select.Box className={styles.item} key={item}>
              <Stack
                direction="row"
                justify="space-between"
                onClick={() => {
                  setPeriod(item)
                }}
              >
                <Typography type="body2" color="black70Color">
                  {item}
                </Typography>
                {item === period && <Icon name="done" />}
              </Stack>
            </Select.Box>
          ))}
        </div>
      </Select.DropDown>
      <div className={styles.verticalDivider} />
      <Select.DropDown
        target={
          <Stack direction="row" gutter="4" align="center">
            <Typography type="caption2" weight="bold" color="primary140Color">
              {printOnlyDateRange(date as [Date, Date])}
            </Typography>
            <Icon name="arrow-down" className={styles.iconStyles} />
          </Stack>
        }
        keepOpened
      >
        <Select.Box>
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            selectRange={multiSelect}
            onChange={handlePick}
            defaultValue={date as any}
            className={clsx("react-calendar", styles.calendar)}
            formatMonthYear={(_, date) => format(date, "MMM yyyy")}
            formatMonth={(_, date) => format(date, "MMM")}
            formatShortWeekday={(_, date) => format(date, "EEEEE")}
          />
        </Select.Box>
      </Select.DropDown>
    </Stack>
  )
}

export default CustomDatePicker
