import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { TrendValue } from "@components/ui/DataChangeWidget/DataChangeWidget"
import { MetricDescription } from "@framework/types/metrics"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"

import styles from "./GoalWidgetCard.module.scss"

export const GoalWidgetCard: React.FC<{
  goalValue: number
  actualValue: number
  delta: number
  metric: MetricDescription
  className?: string
  inline?: boolean
}> = React.memo(
  ({
    goalValue,
    actualValue,
    delta,
    metric: desc,
    className,
    inline = false,
  }) => (
    <div className={clsx(styles.root, { [styles.inline]: inline }, className)}>
      <Stack direction="row" justify="space-between">
        <Typography type="h2" weight="semibold" className={styles.title}>
          {desc.label}
        </Typography>
        <Typography>
          Goal:{" "}
          <span className={styles.goalValue}>
            {goalValue ? desc.formatter(goalValue) : "-"}
          </span>
        </Typography>
      </Stack>

      <Stack direction="row" gutter="8" className={styles.content}>
        <div className={styles.statisticMetric}>
          <Typography type="caption1" weight="semibold" color="gray">
            Month to Date
          </Typography>
          <Typography
            type="h2"
            weight="semibold"
            className={styles.valueContainer}
          >
            {desc.formatter(actualValue)}
          </Typography>
        </div>
        <div className={styles.statisticMetric}>
          <Typography type="caption1" weight="semibold" color="gray">
            vs Last month
          </Typography>
          <Stack
            direction="row"
            align="center"
            gutter="8"
            className={styles.valueContainer}
          >
            <Typography type="h2" weight="semibold">
              {desc.formatter(actualValue)}
            </Typography>
            <TrendValue
              className={styles.metric}
              typographyType="h3"
              weight="bold"
              value={delta > 1000 ? 1000 : delta} // NOTE: ...think more then 100000 percent is imposible value + better ux
              arrowAfter
              renderValue={(v) => `${Math.round(v * 100)}%`}
              metricDir={(desc?.trendFormat ?? positiveTransformDirection)(
                delta
              )}
            />
          </Stack>
        </div>
        <div className={styles.statisticMetric}>
          <Typography type="caption1" weight="semibold" color="gray">
            vs Last year
          </Typography>
          <Stack
            direction="row"
            align="center"
            gutter="8"
            className={styles.valueContainer}
          >
            <Typography type="h2" weight="semibold">
              {desc.formatter(actualValue)}
            </Typography>
            <TrendValue
              className={styles.metric}
              typographyType="h3"
              weight="bold"
              value={delta > 1000 ? 1000 : delta} // NOTE: ...think more then 100000 percent is imposible value + better ux
              arrowAfter
              renderValue={(v) => `${Math.round(v * 100)}%`}
              metricDir={(desc?.trendFormat ?? positiveTransformDirection)(
                delta
              )}
            />
          </Stack>
        </div>
      </Stack>
    </div>
  )
)
export default GoalWidgetCard
