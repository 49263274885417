import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { ADSAnalyticsResponse } from "@services/creative-analytics"
import { MetricsTrendType } from "@framework/types/metrics"
import {
  ComponentDescriptor,
  FCProps,
  NullableRecord,
} from "@framework/types/types"
import RowGroup from "./Table/RowGroup"
import SimpleTextCell from "./Table/TableCell/SimpleTextCell"
import {
  ColumnMapper,
  GetValueCallbackType,
  RenderCallbackType,
} from "./Table/types"

import styles from "./MetricsTableRow.module.scss"
import { RenderCellOptions } from "./Table/utils"

export const makeDescriptor = <
  T extends React.FC<any>,
  N = NullableRecord<FCProps<T>>
>(
  Component: T,
  props: N = {} as N
): ComponentDescriptor<T, N> => ({ Component, defaultProps: props })

export const DefaultCellDescriptor = makeDescriptor(SimpleTextCell)

interface RowProps<T extends object = any> {
  staticBefore?: number
  mapper: ColumnMapper<T>[]
  data: ADSAnalyticsResponse["data"]["Summary"]
  metricsTrend: MetricsTrendType
  onClick?: () => void
  defaultRenderCallback?: RenderCallbackType<T>
  defaultGetValueCallback?: GetValueCallbackType<T>
}

const MetricsTableRow: React.FC<any> = observer(
  <T extends object = any>({
    metricsTrend,
    mapper,
    data,
    staticBefore = 0,
    onClick,
  }: RowProps<T>) => {
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0")

    const staticMapper = mapper.slice(0, staticBefore)
    const mainMapper = mapper.slice(staticBefore, mapper.length)

    const renderDataCell = (
      item: ColumnMapper<T>,
      options?: RenderCellOptions
    ) => {
      if (!data) return <Skeleton count={2} />
      return (
        <Cell
          rowMapper={item}
          data={data}
          metricsTrend={metricsTrend}
          options={options}
        />
      )
    }

    const renderCell = (item: ColumnMapper<T>, options?: RenderCellOptions) => (
      <div className={clsx(styles.cell, styles.bodyCell)}>
        {renderDataCell(item, options)}
      </div>
    )

    return (
      <div className={styles.row}>
        <RowGroup
          name="static"
          mapper={staticMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderCell}
          onClick={onClick}
          key="static"
        />
        <RowGroup
          name="main"
          mapper={mainMapper}
          className={styles.group}
          renderCell={renderCell}
          withShadow
          key="main"
          options={{ shoulDisplayOptions: true }}
        />
      </div>
    )
  }
)

export interface CellProps<T> {
  rowMapper: ColumnMapper<T>
  data: ADSAnalyticsResponse["data"]["Summary"]
  metricsTrend?: MetricsTrendType
  options?: RenderCellOptions
}

export const Cell = observer(
  <T,>({ rowMapper, data, metricsTrend, options }: CellProps<T>) => {
    const props = {
      name: rowMapper.name,
      data,
      metricsTrend,
      options,
    }
    const { Component, defaultProps } = rowMapper.view ?? DefaultCellDescriptor
    return <Component {...defaultProps} {...props} />
  }
)

export default MetricsTableRow
