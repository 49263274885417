import React, { FC } from "react"
import clsx from "clsx"
import { metricNamesListV1 } from "@framework/types/metrics"
import Typography from "@components/ui/Typography/Typography"
import { positiveTransformDirection } from "@components/ui/Typography/Trend/utils"
import { getMetricDescriptor } from "@framework/constants/metrics"
import Trend from "@components/ui/Typography/Trend/Trend"
import { percentageFormatter } from "@services/utils"
import { PeriodMetricsChange } from "./utils"

import styles from "./ChannelPerfomanceDetails.module.scss"

interface SummaryStaticColumnProps {
  data: PeriodMetricsChange
}

const SummaryStaticColumn: FC<SummaryStaticColumnProps> = ({ data }) => (
  <div className={clsx(styles.staticColumnContainer, styles.summaryColumn)}>
    <div>
      {metricNamesListV1.map((metricName) => {
        const metric = getMetricDescriptor(metricName)
        return (
          <Typography type="body1" className={styles.metricItem}>
            {metric.formatter(data[metricName].Value)}
          </Typography>
        )
      })}
    </div>
    <div>
      {metricNamesListV1.map((metricName) => {
        const metric = getMetricDescriptor(metricName)
        const trendFormatter = metric.trendFormat ?? positiveTransformDirection

        const { direction, color } = trendFormatter(
          data[metricName].Changes ?? 0
        )
        return (
          <div className={styles.metricItem}>
            <Trend
              className={styles.trendItem}
              style={{
                background: color === "lightGreen" ? "#D4ECE3" : "#FBE0DE",
              }}
              type="h5"
              direction={direction}
              color={color}
            >
              {percentageFormatter(data[metricName].Changes)}
            </Trend>
          </div>
        )
      })}
    </div>
  </div>
)

export default SummaryStaticColumn
