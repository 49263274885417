import { getMetricDescriptor } from "@framework/constants/metrics"
import {
  MetricDescription,
  MetricNameType,
  PerformanceReport,
} from "@framework/types/metrics"
import { ColumnMapper } from "./types"
import { makeDescriptor } from "../MetricsTable/MetricsTableRow"
import LabelCell from "../MetricsTable/Table/TableCell/LabelCell"
import MetricCell from "../MetricsTable/Table/TableCell/MetricCell"

const defaultLabelCell = makeDescriptor(LabelCell)

const defaultMetricCell = makeDescriptor(MetricCell, {
  getMetric: getMetricDescriptor,
})

const mapper = (
  activeMetrics: MetricDescription[]
): ColumnMapper<PerformanceReport & { Name: string }>[] => {
  const res: ColumnMapper<PerformanceReport & { Name: string }>[] = [
    {
      name: "Name",
      label: "Channel",
      width: "300px",
      view: defaultLabelCell,
    },
  ]
  activeMetrics.forEach((metric) => {
    res.push({
      name: metric.name,
      label: metric.label,
      width: "170px",
      view: defaultMetricCell,
      isSortable: false,
    })
  })
  return res
}

export interface CheckboxItem {
  label: string
  name: MetricNameType
  checked: boolean
}

export const defaultElementOrder: MetricNameType[] = [
  "Cost",
  "Impressions",
  "Clicks",
  "AverageCpc",
  "Cpm",
  "Ctr",
  "Conversions",
  "CostConv",
  "ConversionRate",
  "AOV",
  "Revenue",
  "Roas",
  "Thumbstop",
  "Holdplay",
]

export default mapper
