import React, { FC } from "react"

interface FacebookIconProps {
  width?: number
  height?: number
}

const FacebookIcon: FC<FacebookIconProps> = ({ width = 25, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12.0746C24 5.40933 18.6241 0 12 0C5.3759 0 0 5.40933 0 12.0746C0 18.1057 4.38723 23.1047 10.1339 24V15.5565H7.069V12.0746H10.1339V9.41347C10.1339 6.39171 11.9135 4.71295 14.6571 4.71295C15.967 4.71295 17.3265 4.96166 17.3265 4.96166V7.90881H15.8187C14.3234 7.90881 13.8538 8.85389 13.8538 9.79896V12.0622H17.1905L16.6591 15.544H13.8538V23.9876C19.6128 23.1047 24 18.1057 24 12.0746Z"
      fill="#1877F2"
    />
    <path
      d="M16.6712 15.4643L17.2026 12.0058H13.8658V9.75769C13.8658 8.80659 14.3478 7.88018 15.8308 7.88018H17.3385V4.9404C17.3385 4.9404 15.9791 4.69336 14.6691 4.69336C11.9255 4.69336 10.1459 6.36088 10.1459 9.36243V12.0058H7.08105V15.4643H10.1459V23.8514C10.7515 23.9502 11.3818 23.9996 12.012 23.9996C12.6423 23.9996 13.2726 23.9502 13.8782 23.8514V15.4643H16.6712Z"
      fill="white"
    />
  </svg>
)

export default FacebookIcon
