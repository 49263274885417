import { MetricNameTypeV1 } from "@framework/types/metrics"

export interface TableData {
  data: TableItem[]
}

export type PeriodMetrics = {
  [K in MetricNameTypeV1]: { Value: number }
}

export interface TableItem {
  Metrics: PeriodMetrics
  Period: string
}

export interface OpenMetricsTable {
  isOpenGoogle: boolean
  isOpenFacebook: boolean
}

export type PeriodMetricsChange = {
  [K in MetricNameTypeV1]: { Value: number; Changes: number }
}

export const formatDate = (dateStr: string) => {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
    throw new Error("Неверный формат даты. Ожидается формат YYYY-MM-DD")
  }
  const [year, month, day] = dateStr.split("-")
  const shortYear = year.slice(2)
  return `${day}.${month}.${shortYear}`
}

export default {}
