import React, { FC } from "react"

interface GoogleIconV2Props {
  width?: number
  height?: number
}

const GoogleIconV2: FC<GoogleIconV2Props> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.02 12.2729C24.02 11.422 23.9436 10.6038 23.8018 9.81836H12.5V14.4602H18.9582C18.68 15.9602 17.8345 17.2311 16.5636 18.082V21.0929H20.4418C22.7109 19.0038 24.02 15.9274 24.02 12.2729Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 24.0003C15.74 24.0003 18.4564 22.9257 20.4418 21.093L16.5636 18.0821C15.4891 18.8021 14.1145 19.2275 12.5 19.2275C9.37455 19.2275 6.72909 17.1166 5.78546 14.2803H1.77637V17.3894C3.75091 21.3112 7.80909 24.0003 12.5 24.0003Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.78545 14.2804C5.54545 13.5604 5.40909 12.7913 5.40909 12.0004C5.40909 11.2095 5.54545 10.4404 5.78545 9.72042V6.61133H1.77636C0.963636 8.23133 0.5 10.0641 0.5 12.0004C0.5 13.9368 0.963636 15.7695 1.77636 17.3895L5.78545 14.2804Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 4.77273C14.2618 4.77273 15.8436 5.37818 17.0873 6.56727L20.5291 3.12545C18.4509 1.18909 15.7345 0 12.5 0C7.80909 0 3.75091 2.68909 1.77637 6.61091L5.78546 9.72C6.72909 6.88364 9.37455 4.77273 12.5 4.77273Z"
      fill="#EA4335"
    />
  </svg>
)

export default GoogleIconV2
