import React from "react"
import clsx from "clsx"

import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { SortState } from "../Table"

import styles from "./TableCell.module.scss"

interface HeaderCellProps {
  label: string
  name: string
  sortState: SortState
  onSort?: (column: string) => void
  isSortable?: boolean
  className?: string
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  onSort,
  isSortable,
  sortState,
  label = "",
  name,
  className,
}) => (
  <Typography
    onClick={() => (onSort && isSortable ? onSort(name) : null)}
    className={clsx(styles.defaultPaddings, className, {
      [styles.clickable]: isSortable,
    })}
    type="h5"
    color="black70Color"
  >
    {label}
    <span className={styles.sortArrow}>
      {isSortable ? (
        <Icon
          name={isSortable ? "arrow-right" : "none"}
          rotateAngle={sortState.direction === "asc" ? 90 : -90}
        />
      ) : null}
    </span>
  </Typography>
)

export default HeaderCell
