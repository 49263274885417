import React, { FC } from "react"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Divider from "@components/ui/Divider/Divider"

import styles from "./SimpleMetricTable.module.scss"

interface SimpleMetricTableProps {
  tableHeaders: string[]
  data: JSX.Element[][]
}

const SimpleMetricTable: FC<SimpleMetricTableProps> = ({
  tableHeaders,
  data,
}) => (
  <Stack direction="column" align="center" className={styles.tableContainer}>
    <div
      className={styles.grid}
      style={{ gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr)` }}
    >
      {tableHeaders.map((el) => (
        <Typography type="caption1" weight="semibold" color="black60Color">
          {el}
        </Typography>
      ))}
    </div>
    {data.map((el) => (
      <>
        <Divider />
        <div
          className={styles.grid}
          style={{
            gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr)`,
          }}
        >
          {el.map((el) => el)}
        </div>
      </>
    ))}
  </Stack>
)

export default SimpleMetricTable
