import React from "react"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Table from "./Table"
import { DATA } from "./mockData"
import ChannelPerfomanceDatePicker from "../ChannelPerfomanceDatePicker/ChannelPerfomanceDatePicker"

import styles from "./ChannelPerfomanceDetails.module.scss"

const ChannelPerfomanceDetails = () => (
  <div className={styles.root}>
    <Typography
      type="h1"
      weight="bold"
      color="black100Color"
      className={styles.headerText}
    >
      Channel Performance Details
    </Typography>
    <div className={styles.tableContainer}>
      <Stack direction="row" justify="space-between">
        <ChannelPerfomanceDatePicker />
        <Stack direction="row" gutter="12" className={styles.buttonsContainer}>
          <Button color="secondary">Tabble Setting</Button>
          <Button color="secondary">Export report</Button>
        </Stack>
      </Stack>
      <Table data={DATA} />
    </div>
  </div>
)

export default ChannelPerfomanceDetails
