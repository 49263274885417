import React, { useCallback, useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import moment from "moment"

import DatePicker from "@components/ui/DatePicker/DatePicker"
import Button from "@components/ui/Button/Button"

import styles from "./Filter.module.scss"

export type FilterProps = {
  className?: string
  value?: Date[]
  isDisabled?: boolean
  onSubmit: (date: [Date, Date]) => void
}

export const Filter: React.FC<FilterProps> = ({
  className,
  onSubmit,
  isDisabled,
}) => {
  const defaultDate: [Date, Date] = useMemo(
    () => [
      moment(Date.now()).startOf("month").toDate(),
      moment(Date.now()).toDate(),
    ],
    []
  )

  const [date, setDate] = useState<Date | Date[]>(defaultDate)

  const handleSubmit = useCallback(() => {
    if (Array.isArray(date) && date.length === 2) onSubmit(date as [Date, Date])
  }, [onSubmit, date])

  useEffect(() => {
    onSubmit(defaultDate)
  }, [])

  return (
    <div className={clsx(styles.root, className)}>
      <DatePicker
        defValue={date}
        onChange={setDate}
        multiSelect
        disabled={isDisabled}
      />
      <Button
        onClick={handleSubmit}
        disabled={(Array.isArray(date) && date.length === 0) || isDisabled}
        className={styles.button}
        size="big"
      >
        Apply
      </Button>
    </div>
  )
}

export default Filter
