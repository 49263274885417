import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { PerformanceReportV1 } from "@framework/types/metrics"
import RowGroup from "./RowGroup"
import { ColumnMapper } from "./types"
import SimpleTextCell from "./TableCell/SimpleTextCell"
import { Cell } from "./Row"

import styles from "./Row.module.scss"
import { RenderCellOptions } from "./utils"

interface FooterProps<T extends object = any> {
  staticBefore?: number
  staticAfter?: number
  mapper: ColumnMapper<T>[]
  className?: string
  data: PerformanceReportV1
}

const Footer = observer(
  <T extends object>({
    data,
    mapper,
    staticBefore = 0,
    className,
  }: FooterProps<T>) => {
    if (staticBefore < 0) throw new Error("staticBefore filed should be >= 0 ")

    const beforeMapper = mapper.slice(0, staticBefore)
    const middleMapper = mapper.slice(staticBefore, mapper.length)

    const renderFirstCell = () => (
      <div className={clsx(styles.cell, styles.footerCell)}>
        <SimpleTextCell text="Total" />
      </div>
    )

    const renderCell = (item: ColumnMapper<T>, options?: RenderCellOptions) => (
      <div className={clsx(styles.cell, styles.footerCell)}>
        <Cell rowMapper={item} data={data} />
      </div>
    )

    return (
      <div className={clsx(styles.footer, className)}>
        <RowGroup
          name="static"
          mapper={beforeMapper}
          className={clsx(styles.group, styles.staticGroup)}
          renderCell={renderFirstCell}
        />
        <RowGroup
          name="main"
          mapper={middleMapper}
          className={styles.group}
          renderCell={renderCell}
          options={{ shoulDisplayOptions: false }}
        />
      </div>
    )
  }
)

export default Footer
