import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"

import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import accountService from "@services/account.service"
import Templates from "@components/ui/Templates"
import CampaignSegmentationTable from "./CampaignSegmentationTable"

import styles from "./CampaignSegmentation.module.scss"

export const CampaignSegmentation: React.FC = observer(() => {
  const history = useHistory()

  const { accountDashboardStore, accountStore } = useStore()

  const { loadPerformanceSegmentedReport, currPeriod } = accountDashboardStore
  const { accountId } = accountStore

  const downloadReportLink = useMemo(
    () =>
      currPeriod && accountId
        ? accountService.getReportDownloadLink(accountId, currPeriod)
        : undefined,
    [currPeriod, accountId]
  )

  const handleChange = () =>
    history.push(`/account/${accountId}/manage-profile`)

  useEffect(() => {
    if (accountId !== null && accountStore.account?.isConnected) {
      loadPerformanceSegmentedReport(accountId!, currPeriod.from, currPeriod.to)
    }
  }, [
    loadPerformanceSegmentedReport,
    accountId,
    currPeriod,
    accountStore.account?.isConnected,
  ])

  return (
    <Box className={styles.root}>
      {/* /// Campaign Segmentation /// */}
      <div className={styles.section}>
        <Templates.Header
          left={
            <Typography className={styles.title} type="h2" weight="bold">
              Campaign Segmentation
            </Typography>
          }
          right={
            <>
              <Button variant="ghost" color="primary" onClick={handleChange}>
                Edit Segments
              </Button>

              <Button
                disabled={!downloadReportLink}
                href={downloadReportLink}
                variant="outlined"
                color="primary"
                as="a"
                download
              >
                Download Report
              </Button>
            </>
          }
        />

        <CampaignSegmentationTable />

        <div className={styles.footer}>
          <Button variant="contained" color="primary">
            View Campaigns
          </Button>
        </div>
      </div>
    </Box>
  )
})

export default CampaignSegmentation
