import React from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "@store/index"
import Sticky from "@components/ui/Sticky/Sticky"
import Typography from "@components/ui/Typography/Typography"
import ToolBar from "@components/ui/ToolBar/ToolBar"
import BackButton from "@framework/prototypes/BackButton"

import styles from "./SettingsLayout.module.scss"

export interface SettingsLayoutProps {
  onBack?: React.MouseEventHandler
}

export const SettingsLayout: React.FC<SettingsLayoutProps> = observer(
  ({ children, onBack }) => {
    const { authStore } = useStore()

    return (
      <div className={styles.root} id="scroll-container">
        <div className={styles.container}>
          <div className={styles.header}>
            <Sticky stickyStyle={{ zIndex: 5 }} topOffset={0}>
              <ToolBar className={styles.mainHeader}>
                <Typography type="h2" color="primary">
                  ADORO
                </Typography>
              </ToolBar>
            </Sticky>
          </div>

          <div className={styles.body}>
            {authStore.user?.isOnboarded && (
              <BackButton className={styles.backButton} onClick={onBack} />
            )}
            <div className={styles.bodyContainer}>{children}</div>
          </div>
        </div>
      </div>
    )
  }
)

export default SettingsLayout
