import clsx from "clsx"
import React from "react"

import Typography from "@components/ui/Typography/Typography"
import BackButton from "@framework/prototypes/BackButton"

import styles from "./PublicLayout.module.scss"

type PublicLayoutProps = {
  children?: React.ReactNode
  color?: "primary" | "default"
  after?: React.ReactNode
  before?: React.ReactNode
  className?: string
  showBackButton?: boolean
  onBackButton?: () => void
}

const PublicLayout: React.FC<PublicLayoutProps> = ({
  color = "default",
  after,
  before,
  children,
  className,
  showBackButton = false,
  onBackButton,
}) => (
  <div className={clsx(styles.root, styles[`color-${color}`], className)}>
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.before}>{before}</span>

        <Typography
          type="h2"
          color={color === "default" ? "primary" : "light"}
          className={styles.title}
        >
          ADORO
        </Typography>

        <span className={styles.after}>{after}</span>
      </div>
      {showBackButton && (
        <BackButton className={styles.backButton} onClick={onBackButton} />
      )}

      <div className={styles.wrapper}>{children}</div>
    </div>
  </div>
)

export default PublicLayout
