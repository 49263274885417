import React from "react"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import OnClickTooltip from "@components/ui/Tooltip/TooltipContainer/OnClickTooltipContainer"
import Icon from "@components/ui/Icon/Icon"

import styles from "./HistoricalMonthSpend.module.scss"
import StackBarChart from "./StackBarChart"

const ICON_INFO_DECRIPTION = "MOCK DATA"

const AdsLabelsMock = ["Google Ads", "Facebook", "TikTok"]

const COLORS_FOR_CHART = [
  "#D1E7EC",
  "#4196C6",
  "#58B7C9",
  "#A8DAC8",
  "#EAC974",
  "#005B70",
  "#FBE0DE",
]

const HistoricalMonthSpend = () => (
  <div className={styles.root}>
    <Stack direction="row" gutter="8" align="center">
      <Typography type="h1" color="dark" weight="bold">
        Historical Month Ad Spend
      </Typography>
      <OnClickTooltip
        placement="bottom"
        toolTip={
          <Typography className={styles.toolTip}>
            {ICON_INFO_DECRIPTION}
          </Typography>
        }
      >
        <Typography color="lightGray">
          <Icon name="information-solid" />
        </Typography>
      </OnClickTooltip>
    </Stack>
    <Typography
      type="subhead1"
      color="black60Color"
      weight="semibold"
      upperCase
    >
      (YEAR 2025)
    </Typography>
    <Stack
      direction="row"
      justify="flex-start"
      className={styles.legendWrapper}
      gutter="12"
    >
      {AdsLabelsMock.map((el, index) => (
        <Stack direction="row" gutter="8">
          <div
            className={styles.colorMark}
            style={{ backgroundColor: COLORS_FOR_CHART[index] }}
          />
          <Typography type="body2" color="black80Color">
            {el}
          </Typography>
        </Stack>
      ))}
    </Stack>
    <div className={styles.chartWrapper}>
      <StackBarChart data={[]} colors={[]} labels={[]} />
    </div>
  </div>
)

export default HistoricalMonthSpend
