import React, { FC } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import DatePicker from "@components/ui/DatePicker/DatePicker"
import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import TextField from "@components/ui/TextField/TextField"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import Stack from "@components/ui/Stack/Stack"
import IconButton from "@components/ui/Button/IconButton"
import { useSearchContext } from "@framework/prototypes/SearchContext"
import CampaignsSelect from "@framework/prototypes/CampaignsSelect/CampaignsSelect"
import { useSelectedCampaignsContext } from "@framework/prototypes/SelectedCampaignsContext"
import usePrevious from "@framework/hooks/usePrevious"
import { useSelectedAdGroupsContext } from "@framework/prototypes/SelectedAdGroupsContext"
import uniqBy from "lodash/uniqBy"
import { BaseCampaignReportType } from "@framework/types/account"

import styles from "./Header.module.scss"

export type HeaderProps = {
  view?: string
  viewOptions?: string[]
  isDisabled?: boolean
  onViewChange?: (option: string) => void
  className?: string
}

const Header: React.FC<HeaderProps> = ({
  isDisabled,
  view,
  viewOptions = [],
  onViewChange,
  className,
}) => {
  const { manageCampaignStore } = useStore()
  const { campaignsReport, dateFilter, setDateFilter } = manageCampaignStore

  const [date, setDate] = React.useState<Date | Date[]>(dateFilter)

  const searchContext = useSearchContext()

  const handleSubmit = React.useCallback(() => {
    if (Array.isArray(date) && date.length >= 2) {
      setDateFilter(date)
    }
  }, [setDateFilter, date])

  const handleViewChange = (option: string) => {
    searchContext.setSearchQuery("")
    onViewChange?.(option)
  }

  React.useEffect(() => {
    if (dateFilter && dateFilter !== date) setDate(dateFilter)
  }, [dateFilter])

  return (
    <Templates.TripleHeader
      className={clsx(className)}
      left={
        <Stack direction="row">
          <TextField
            placeholder="Search"
            disabled={isDisabled}
            before={<Icon name="search" />}
            after={
              searchContext.searchQuery ? (
                <IconButton onClick={() => searchContext.setSearchQuery("")}>
                  <Icon name="cross" />
                </IconButton>
              ) : null
            }
            onChange={(e) => searchContext.setSearchQuery(e.target.value)}
            value={searchContext.searchQuery}
          />

          <SimpleSelect
            disabled={isDisabled}
            value={view}
            options={viewOptions}
            onSelect={handleViewChange}
            className={styles.selector}
          />

          {campaignsReport != null && (
            <CampaignsPicker isDisabled={isDisabled} />
          )}

          {campaignsReport != null && (
            <AdGroupsPicker isDisabled={isDisabled} />
          )}
        </Stack>
      }
      right={
        <div className={styles.right}>
          <DatePicker
            disabled={isDisabled}
            defValue={date}
            onChange={setDate}
            className={styles.selector}
            multiSelect
          />
          <Button
            onClick={handleSubmit}
            disabled={(Array.isArray(date) && date.length === 0) || isDisabled}
            size="big"
          >
            Apply
          </Button>
        </div>
      }
    />
  )
}

export default observer(Header)

interface PickerProps {
  isDisabled?: boolean
}

const CampaignsPicker: FC<PickerProps> = observer(({ isDisabled }) => {
  const {
    manageCampaignStore: { campaignsReport },
  } = useStore()

  const prevCampaignsReport = usePrevious(campaignsReport)
  const campaignContext = useSelectedCampaignsContext()

  const campaigns = React.useMemo(
    () => campaignsReport?.map((it) => it.Campaign) ?? [],
    [campaignsReport]
  )

  React.useEffect(() => {
    if (prevCampaignsReport == null) {
      campaignContext.setCampaigns(campaigns.map((it) => it.Id.toString()))
      return
    }

    const newSelected = campaigns.reduce<string[]>((acc, it) => {
      const id = it.Id.toString()

      if (campaignContext.selectedCampaigns.includes(id)) acc.push(id)

      return acc
    }, [])

    campaignContext.setCampaigns(newSelected)
  }, [campaigns])

  return (
    <CampaignsSelect
      unit="Campaign"
      options={campaigns}
      value={campaignContext.selectedCampaigns}
      onChange={campaignContext.setCampaigns}
      className={styles.selector}
      disabled={isDisabled}
    />
  )
})

const AdGroupsPicker: FC<PickerProps> = observer(({ isDisabled }) => {
  const {
    manageCampaignStore: { campaignsReport },
  } = useStore()

  const campaignContext = useSelectedCampaignsContext()
  const adgroupsContext = useSelectedAdGroupsContext()

  const adGroups = React.useMemo(() => {
    const allAdGroups =
      campaignsReport?.flatMap<BaseCampaignReportType>((it) => {
        if (
          !it.Adgroups?.length ||
          !campaignContext.selectedCampaigns.includes(it.Campaign.Id.toString())
        )
          return []

        return it.Adgroups ?? []
      }) ?? []

    return uniqBy(allAdGroups, (it) => it.Id)
  }, [campaignsReport, campaignContext.selectedCampaigns])

  React.useEffect(() => {
    adgroupsContext.setAdGroups(adGroups.map((it) => it.Id.toString()))
  }, [adGroups])

  return (
    <CampaignsSelect
      unit="Ad Group"
      options={adGroups}
      value={adgroupsContext.selectedAdGroups}
      onChange={adgroupsContext.setAdGroups}
      className={styles.selector}
      disabled={isDisabled}
    />
  )
})
