import React, { FC, ReactNode } from "react"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import AppleIcon from "@root/assets/svgIcons/AppleIcon"
import GoogleIconV2 from "@root/assets/svgIcons/GoogleIconV2"
import FacebookIcon from "@root/assets/svgIcons/FacebookIcon"

import styles from "./Shared.module.scss"

type IconVariant = "Google" | "Apple" | "Facebook"

type IconMapType = {
  [K in IconVariant]: React.ReactNode
}

interface SSOButtonProps {
  variant?: "Google" | "Apple" | "Facebook"
  onClick?: () => void
}

const IconMap: IconMapType = {
  Google: <GoogleIconV2 />,
  Apple: <AppleIcon />,
  Facebook: <FacebookIcon />,
}

const SSOButton: FC<SSOButtonProps> = ({ variant = "Google", onClick }) => (
  <Button
    variant="outlined"
    color="secondary"
    className={styles.sssoButton}
    onClick={onClick}
  >
    <Stack direction="row" gutter="4" justify="center" align="center">
      {IconMap[variant]}
      <Typography type="body2" weight="bold">
        Sign In with {variant}
      </Typography>
    </Stack>
  </Button>
)

export default SSOButton
