/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

import SelectAccountChannelModal from "@components/modals/SelectAccountChannelModal/SelectAccountChannelModal"
import SelectCustomerModal from "@components/modals/SelectCustomerModal/SelectCustomerModal"
import Box from "@components/ui/Box/Box"
import Button from "@components/ui/Button/Button"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/Loader"
import NoData from "@components/ui/NoData/NoData"
import Typography from "@components/ui/Typography/Typography"
import { formatCustomerID } from "@components/utils/stringUtils"
import useQueryParams from "@framework/hooks/useQueryParams"
import { Option } from "@framework/types/types"
import { mbNoData } from "@services/utils"
import { useStore } from "@store/index"
import {
  CustomerType,
  LinkedAccount,
  LinkedAccountKeys,
} from "@store/account-linking/account-linking"
import { channelOptions } from "@store/account-linking/constants"
import useAccountLinkRedirect from "@framework/prototypes/LinkAccount/useAccountLinkRedirect"

import styles from "./LinkedAccounts.module.scss"

type LinkedAccountsProps = {
  className?: string
}

const mapper = [
  { name: "channel", label: "Channel" },
  {
    name: "accountName",
    label: "Account name",
    renderCallback: (value: string) => mbNoData(value),
  },
  {
    name: "adAccountId",
    label: "Customer id",
    renderCallback: formatCustomerID,
  },
  { name: "status", label: "Status" },
  { name: "adoroAccountName", label: "Adoro Account Name" },
]

const formMapper = [
  { name: "channel", label: "Channel" },
  { name: "accountName", label: "Account name" },
  {
    name: "adAccountId",
    label: "Customer id",
    renderCallback: formatCustomerID,
  },
  {
    name: "adoroAccountName",
    label: "Adoro Account Name",
  },
  { name: "status", label: "Status" },
  { name: "adoroAccountName", label: "" },
]

const LinkedAccounts: React.FC<LinkedAccountsProps> = observer(
  ({ className }) => {
    const {
      clientStore: { clientId, client },
      clientProfileStore: { isEdit, setIsEdit },
      accountStore: {
        loading: accountLoading,
        clientAccountList: accounts,
        loadClientsAccounts,
      },
      accountLinkingStore: {
        linkPending,
        linkClientToAccount,
        loadCustomerList,
      },
    } = useStore()

    const [code, setCode] = useState<string>()
    const [isCustomersListShown, setCustomersListShown] = useState(false)
    const [isChannelsModalShown, setChannelsModalShown] = useState(false)

    const history = useHistory()
    const params = useQueryParams()

    const linkedAccounts: LinkedAccount[] = useMemo(() => {
      if (!accounts) return []

      return accounts
        .filter(({ adAccountId }) => !!adAccountId)
        .map(({ adAccountId, name }) => ({
          adAccountId: adAccountId.replaceAll("-", ""),
          accountName: "",
          adoroAccountName: name,
          channel: channelOptions[0],
          isConnected: true,
          status: "Connected",
        }))
    }, [accounts])

    const linkedMCCAccounts: LinkedAccount[] = useMemo(() => {
      if (!client?.mccAccountId) return []

      return [
        {
          adAccountId: client.mccAccountId,
          accountName: "",
          adoroAccountName: client.mccAccountName ?? "",
          channel: channelOptions[0],
          isConnected: true,
          status: "Connected",
        },
      ]
    }, [client])

    const handleLinkAccount = async (selected: CustomerType) => {
      if (!clientId) return
      const error = await linkClientToAccount(clientId, selected)
      if (error == null) setCustomersListShown(false)
    }

    const { redirect: linkRedirect } = useAccountLinkRedirect()

    const handleSubmitAccountChannel = (item: string) => {
      if (!clientId) return
      setChannelsModalShown(false)
      linkRedirect("client", { id: clientId })
    }

    const handleLinkNewAccount = useCallback(
      () => setChannelsModalShown(true),
      []
    )

    const handleLinkModalClose = useCallback(() => {
      setCustomersListShown(false)
      setIsEdit(false)
    }, [setCustomersListShown])

    useEffect(() => {
      const { code } = params
      if (code) {
        setCode(code)
        history.replace(history.location.pathname)
      }
    }, [params])

    useEffect(() => {
      if (clientId && code) {
        setIsEdit(true)
        loadCustomerList(code, clientId, "client")
        setCustomersListShown(true)
      }
    }, [code])

    useEffect(() => {
      if (clientId) loadClientsAccounts(clientId)
    }, [clientId])

    useEffect(() => () => setIsEdit(false), [])

    return (
      <div className={clsx(styles.root, className)}>
        <Loader isLoading={accountLoading} />
        <Typography type="h2" className={styles.title}>
          Linked MCC accounts
        </Typography>
        <div className={styles.container}>
          {linkedMCCAccounts.length ? (
            isEdit ? (
              linkedMCCAccounts.map((linkedAccount) => (
                <RowForm data={linkedAccount} />
              ))
            ) : (
              <>
                <div className={styles.row}>
                  {mapper.map(({ name, label }) => (
                    <Typography
                      type="subhead1"
                      color="gray"
                      upperCase
                      key={name}
                    >
                      {label}
                    </Typography>
                  ))}
                </div>
                <div className={styles.list}>
                  {linkedMCCAccounts.map((linkedAccount) => (
                    <Row data={linkedAccount} key={linkedAccount.adAccountId} />
                  ))}
                </div>
              </>
            )
          ) : (
            <NoData>None linked accounts added</NoData>
          )}
        </div>
        <Typography type="h2" className={styles.title}>
          Linked accounts sdvfdv
        </Typography>
        <div className={styles.container}>
          {linkedAccounts.length ? (
            isEdit ? (
              linkedAccounts.map((linkedAccount) => (
                <RowForm data={linkedAccount} />
              ))
            ) : (
              <>
                <div className={styles.row}>
                  {mapper.map(({ name, label }) => (
                    <Typography
                      type="subhead1"
                      color="gray"
                      upperCase
                      key={name}
                    >
                      {label}
                    </Typography>
                  ))}
                </div>
                <div className={styles.list}>
                  {linkedAccounts.map((linkedAccount) => (
                    <Row data={linkedAccount} key={linkedAccount.adAccountId} />
                  ))}
                </div>
              </>
            )
          ) : (
            <NoData>None linked accounts added</NoData>
          )}
          {isEdit && (
            <Button
              onClick={handleLinkNewAccount}
              startIcon={{ name: "plus", circle: true }}
              className={styles.button}
            >
              Link Account
            </Button>
          )}
        </div>
        <SelectCustomerModal
          isOpen={isCustomersListShown}
          isLoading={linkPending}
          onSubmit={handleLinkAccount}
          onClose={handleLinkModalClose}
        />
        <SelectAccountChannelModal
          isOpen={isChannelsModalShown}
          onClose={() => setChannelsModalShown(false)}
          onSubmit={handleSubmitAccountChannel}
        />
      </div>
    )
  }
)

type RowProps = {
  data: LinkedAccount
}

const Row: React.FC<RowProps> = ({ data }) => (
  <Box className={styles.row} padding="8px 6px 6px 6px">
    {mapper.map(({ name, renderCallback }) => {
      const item = data[name as LinkedAccountKeys]
      if (item === undefined) return null
      if (typeof item !== "string" && typeof item !== "boolean") {
        return (
          <Typography color="gray" className={styles.cell} key={name}>
            <img src={item.iconSrc} alt={item.label} className={styles.icon} />
            {item.label}
          </Typography>
        )
      }

      return (
        <Typography color="gray" className={styles.cell} key={name}>
          {renderCallback?.(item as string) ?? item}
        </Typography>
      )
    })}
  </Box>
)

const RowForm: React.FC<RowProps> = ({ data }) => (
  <Box className={styles.box} padding="10px 20px">
    <div className={styles.rowContainer}>
      {formMapper.map(({ label, name }) => (
        <Typography type="subhead1" color="gray" upperCase key={name}>
          {label}
        </Typography>
      ))}
    </div>
    <div className={styles.rowContainer}>
      <div style={{ zIndex: 1 }}>
        <Button variant="outlined">
          <span>Google Ads Link</span>
          <div className={styles.iconWrapper}>
            <Icon name="done" />
          </div>
        </Button>
      </div>
      <Typography type="h4">{data.accountName || "-"}</Typography>
      <Typography type="h4">{formatCustomerID(data.adAccountId)}</Typography>
      <Typography type="h4">{data.adoroAccountName}</Typography>
      <div className={styles.status}>
        <Typography type="h4" color="primary">
          Connected
        </Typography>
        <ToggleButton checked />
      </div>
      <div style={{ zIndex: 1, justifySelf: "flex-end" }}>
        <Button disabled variant="outlined" startIcon={{ name: "trash" }}>
          <span>Delete</span>
        </Button>
      </div>
    </div>
  </Box>
)

export default LinkedAccounts
