import React from "react"
import Typography from "@components/ui/Typography/Typography"
import { metricNamesListV1 } from "@framework/types/metrics"
import { metricsDescriptorsMap } from "@framework/constants/metrics"
import styles from "./ChannelPerfomanceDetails.module.scss"

const MetricsColumn = () => (
  <div className={styles.metricsColumnContianer}>
    {metricNamesListV1.map((el) => (
      <Typography className={styles.item} type="h4">
        {metricsDescriptorsMap[el].label}
      </Typography>
    ))}
  </div>
)

export default MetricsColumn
