import React, { FC } from "react"

interface GoogleAdsIconProps {
  width?: number
  height?: number
}

const GoogleAdsIcon: FC<GoogleAdsIconProps> = ({ height = 22, width = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13114_137942)">
      <rect width="22" height="22" fill="white" />
      <path
        d="M8.69427 2.29688C8.37001 2.5615 8.09632 2.88264 7.88646 3.24477L0.958181 15.1935C0.495788 15.9964 0.369919 16.9497 0.608065 17.8451C0.846211 18.7406 1.42902 19.5054 2.2292 19.9725C3.03212 20.4349 3.9854 20.5608 4.88083 20.3226C5.77625 20.0845 6.54105 19.5017 7.00818 18.7015L12.9632 8.43195C11.6492 6.54562 9.7324 3.79434 8.69427 2.29688Z"
        fill="#FEBD00"
      />
      <path
        d="M3.98313 20.4428C5.91435 20.4428 7.47992 18.8772 7.47992 16.946C7.47992 15.0148 5.91435 13.4492 3.98313 13.4492C2.0519 13.4492 0.486328 15.0148 0.486328 16.946C0.486328 18.8772 2.0519 20.4428 3.98313 20.4428Z"
        fill="#25A84A"
      />
      <path
        d="M14.7838 18.7087C15.7495 20.3812 17.8881 20.9542 19.5606 19.9886C21.233 19.023 21.8061 16.8844 20.8405 15.2119L13.9343 3.25011C12.9687 1.57762 10.8301 1.00458 9.1576 1.9702C7.48511 2.93581 6.91207 5.07442 7.87769 6.74691L14.7838 18.7087Z"
        fill="#2E8ADD"
      />
    </g>
    <defs>
      <clipPath id="clip0_13114_137942">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GoogleAdsIcon
