import { ChartOptions } from "chart.js"

export const simpleChartOptions: ChartOptions<"line"> = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 5,
      bottom: 5,
    },
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 0,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      suggestedMin: 0,
      grid: {
        display: false,
      },
    },
  },
}

export const areaChartOptions: ChartOptions<"line"> = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      // left: 5,
      // right: 5,
      top: 5,
      bottom: 5,
    },
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    tooltip: {
      position: "nearest",
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 2,
      hitRadius: 5,
      backgroundColor: "white",
      hoverRadius: 4,
      hoverBackgroundColor: "white",
      hoverBorderWidth: 1.5,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        maxTicksLimit: 10,
      },
    },
    y: {
      suggestedMin: 0,
      ticks: {
        font: { family: "'Poppins'" },
        padding: 10,
      },
      grid: {
        drawBorder: false,
      },
    },
  },
}

export default { options: simpleChartOptions }
