import React, { FC } from "react"
import Stack from "@components/ui/Stack/Stack"
import Typography from "@components/ui/Typography/Typography"
import { ScrollSyncPane } from "react-scroll-sync"
import { formatDate, TableData } from "./utils"
import styles from "./ChannelPerfomanceDetails.module.scss"

interface TableHeaderProps {
  data?: TableData["data"]
  staticDate: string
  summaryStaticDate: string
}

const TableHeader: FC<TableHeaderProps> = ({
  data,
  staticDate,
  summaryStaticDate,
}) => (
  <div className={styles.mainGrid}>
    <div />
    <ScrollSyncPane>
      <Stack gutter="0" direction="row" className={styles.dateContainer}>
        {data?.map((el) => (
          <Typography
            className={styles.dataItem}
            type="caption1"
            color="black60Color"
          >
            {formatDate(el.Period)}
          </Typography>
        ))}
      </Stack>
    </ScrollSyncPane>
    <Typography
      className={styles.dataItem}
      type="caption1"
      color="black60Color"
    >
      {formatDate(staticDate)}
    </Typography>
    <Typography
      className={styles.dataItem}
      type="caption1"
      color="black60Color"
    >
      {formatDate(summaryStaticDate)}
    </Typography>
  </div>
)

export default TableHeader
