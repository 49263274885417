import { ExtendedMetricNameTypeV1 } from "@framework/types/creativeAnalytics"
import { PerformanceReport } from "@framework/types/metrics"

export type ViewType = "table" | "card"

// types can be change during API integration
export const metaCampaignStatusNames = [
  "INACTIVE",
  "IN_REVIEW",
  "ACTIVE",
  "LOW_PERFORMANCE",
  "FAILED",
  "PAUSED",
  "DELETED",
] as const

export const FILTER_NAMES: Record<string, ExtendedMetricNameTypeV1> = {
  CAMPAIGNS: "campaigns",
  AD_SETS: "adSets",
  CREATIVE_TYPE: "creativeType",
  NAME: "name",
} as const

export const ALLOWED_FILTER_NAMES = Object.values(FILTER_NAMES)

export type MetaCampaignStatus = (typeof metaCampaignStatusNames)[number]

export type MetaCampaignReportData = {
  Name: string
  Id: string
  AdType: string
  VideoURL: string
  ThumbnailURL: string
  CampaignName: string
  AdsetName: string
  Status?: MetaCampaignStatus
  totalViews?: number
  Type: string
} & PerformanceReport

export type MetaCampaignReportDataV1 = PerformanceReport
