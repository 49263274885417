import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import FormTextField from "@framework/prototypes/FormTextField"
import Typography from "@components/ui/Typography/Typography"
import Button from "@components/ui/Button/Button"
import Stack from "@components/ui/Stack/Stack"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import { useStore } from "@store/index"
import { emailValidator } from "@components/utils/validators"
import PublicLayout from "@root/layouts/public/PublicLayout"

import styles from "./index.module.scss"

export const validationSchema = yup.object({
  email: emailValidator.clone().trim().required("Can not be empty").default(""),
})

type FormType = yup.InferType<typeof validationSchema>

const initialValues: FormType = validationSchema.getDefault()

const RestorePassword: React.FC = observer(() => {
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const history = useHistory()
  const alert = useAlert()
  const { userStore } = useStore()

  const handleLoginRedirect = () => history.push("/login")

  const resendLink = () => {
    formik.handleSubmit()
    alert.success("Llink resent!")
  }

  const handleSubmit = async (form: FormType) => {
    const error = await userStore.restorePassword(validationSchema.cast(form))
    if (error) {
      alert.error(
        <AlertMessage
          title="Failed to request password reset"
          description={error}
        />
      )
      return
    }
    setSubmitted(true)
  }

  const formik = useFormik<FormType>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <PublicLayout onBackButton={handleLoginRedirect} showBackButton>
      <FormikProvider value={formik}>
        <div className={styles.root}>
          {!isSubmitted ? (
            <form style={{ maxWidth: "346px" }} onSubmit={formik.handleSubmit}>
              <Stack align="stretch" gutter="16">
                <Typography type="h1" color="dark" bold>
                  Forgot password?
                </Typography>
                <Typography type="body1">
                  Enter your email address below, and we&apos;ll send you a link
                  to reset your password.
                </Typography>

                <FormTextField name="email" label="Email" placeholder="Email" />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={userStore.isLoading}
                >
                  Send
                </Button>
              </Stack>
            </form>
          ) : (
            <div style={{ maxWidth: "580px" }}>
              <Stack align="stretch">
                <Typography type="h1" color="dark" bold>
                  Check your inbox
                </Typography>
                <Typography
                  type="body1"
                  color="dark"
                  style={{ maxWidth: "346px" }}
                >
                  A password reset link has been sent to{" "}
                  <b>{formik.values.email}</b>. Please check your inbox to reset
                  your password.
                </Typography>
                <Stack
                  direction="row"
                  align="center"
                  justify="center"
                  gutter="0"
                >
                  <Typography type="body1">
                    Did not receive the link?
                  </Typography>
                  <Button variant="ghost" color="primary" onClick={resendLink}>
                    Resend it
                  </Button>
                </Stack>
              </Stack>
            </div>
          )}
        </div>
      </FormikProvider>
    </PublicLayout>
  )
})

export default RestorePassword
