import React, { FC, useEffect } from "react"
import clsx from "clsx"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import { MetricNameType } from "@framework/types/metrics"
import { CheckboxItem } from "../OverallKPIs/utils"
import DraggableList from "./DraggableList"

import styles from "./MetricsSideBar.module.scss"

interface MetricsSideBarProps {
  isOpen: boolean
  onSelect: (value: MetricNameType) => void
  metrics: CheckboxItem[]
  setMetrics: React.Dispatch<React.SetStateAction<CheckboxItem[]>>
  onSave: () => void
  onReset: () => void
  onCancel: () => void
}

const MetricsSideBar: FC<MetricsSideBarProps> = ({
  isOpen,
  onSelect,
  metrics,
  setMetrics,
  onSave,
  onReset,
  onCancel,
}) => (
  <SidebarContainer isOpen={isOpen} onClose={onSave} className={styles.root}>
    <Stack direction="row" justify="space-between" className={styles.header}>
      <Typography type="h1" weight="bold" color="black100Color">
        Select KPIs
      </Typography>
      <Button
        color="secondary"
        onClick={onReset}
        before={<Icon name="refresh" />}
      >
        Reset All
      </Button>
    </Stack>

    <main className={styles.mainContainer}>
      <Typography type="h2" color="black100Color">
        Perfoms
      </Typography>
      <DraggableList
        onSelectItem={onSelect}
        setMetrics={setMetrics}
        metrics={metrics}
      />
    </main>
    <Templates.Header
      className={clsx(styles.footer)}
      offset="none"
      left={
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      }
      right={
        <Button color="primary" onClick={onSave}>
          Apply
        </Button>
      }
    />
  </SidebarContainer>
)

export default MetricsSideBar
